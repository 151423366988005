import React, { useState, useRef } from "react";
import {
  Bot,
  Zap,
  Brain,
  PanelRight,
  AreaChart,
  CheckCircle2,
  Settings,
  Users,
  BarChart,
  MessageSquare,
  PlusCircle,
  StatusBadge,
  Badge,
  PlayCircle,
  PauseCircle,
  ChevronRight,
  Lightbulb,
  Workflow,
  Target,
  Terminal,
} from "lucide-react";
import Navigation from "../../navigation/Navigation";
import Footer from "../../components/footer/Footer";
import FaqSection from "../../components/FaqSection/FaqSection";
import { agents } from "../../utils/AgentsData"; // Assuming you have this data file
import WaitlistModal from "../../components/WaitlistModal/WaitlistModal";

const AgentsPage = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showAgentForm, setShowAgentForm] = useState(false);
  const videoRef = useRef(null);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  // Agent-specific FAQs
  const agentFaqs = [
    {
      question: "How do your AI agents differ from regular chatbots?",
      answer:
        "Our AI agents go beyond simple chatbots by leveraging advanced natural language understanding and autonomous decision-making capabilities. They can handle complex workflows, learn from interactions, integrate with your existing systems, and proactively take action without constant human supervision.",
    },
    {
      question: "Can I customize the AI agents to match my business needs?",
      answer:
        "Absolutely! Each agent comes with a customization interface where you can define business rules, preferences, communication style, and integration parameters. You can tailor the agent's knowledge base with your specific property details, pricing strategies, and standard operating procedures.",
    },
    {
      question: "How do the agents handle sensitive customer information?",
      answer:
        "Security is our top priority. All agents comply with industry data protection standards, encrypt sensitive information, and operate with strict access controls. You maintain full ownership of your data, and agents can be configured to comply with specific regulatory requirements in your region.",
    },
    {
      question: "What kind of integration is required to set up the AI agents?",
      answer:
        "Our agents use API-based integration with common property management systems, CRMs, and communication platforms. We provide pre-built connectors for popular systems, and our implementation team can develop custom integrations for your specific tech stack.",
    },
    {
      question: "How do I monitor and improve my AI agent's performance?",
      answer:
        "Each agent comes with a comprehensive analytics dashboard that tracks key metrics like response time, resolution rate, customer satisfaction, and business outcomes. You can review conversation logs, identify improvement areas, and fine-tune your agent's instructions based on performance data.",
    },
  ];

  const toggleVideo = () => {
    if (videoRef.current) {
      if (videoPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setVideoPlaying(!videoPlaying);
    }
  };

  return (
    <div className="bg-black min-h-screen">
      <Navigation />

      {/* Hero Section with Video */}
      <div className="w-[99%] mx-auto bg-white rounded-2xl overflow-hidden mt-4">
        <div className="relative">
          {/* Video Container */}
          <div className="relative aspect-video w-[100%] max-h-[500px] overflow-hidden bg-gray-900">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-900/30 to-black/40 z-10"></div>

            {/* Placeholder for video - replace with actual video source */}
            {/* <video
              ref={videoRef}
              className="w-full h-full object-cover"
              poster="/agent-poster.jpg" // Replace with actual poster image
              muted
              loop
            >
              <source
                src="https://www.youtube.com/watch?v=_lsXx-3jz-0"
                type="video/mp4"
              />{" "}
             
              Your browser does not support the video tag.
            </video>

           
            <button
              onClick={toggleVideo}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 bg-white/20 backdrop-blur-sm p-4 rounded-full hover:bg-white/30 transition-all duration-300"
            >
              {videoPlaying ? (
                <PauseCircle className="w-12 h-12 text-white" />
              ) : (
                <PlayCircle className="w-12 h-12 text-white" />
              )}
            </button> */}

            {/* Overlay Content */}
            <div className="absolute inset-0 z-10 flex items-center">
              <div className="max-w-3xl mx-auto px-6 text-center">
                <h1 className="font-mono text-4xl md:text-5xl font-bold text-white mb-4 tracking-tight">
                  AI Agents for Real Estate & Hospitality
                </h1>
                <p className="text-gray-200 text-lg md:text-xl mb-8 max-w-2xl mx-auto">
                  Autonomous digital assistants that work 24/7 to streamline
                  operations, enhance guest experiences, and drive business
                  growth.
                </p>
                <div className="flex flex-wrap justify-center gap-4">
                  <button
                    onClick={() => setShowWaitlistModal(true)}
                    className="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-mono rounded-lg transition-colors flex items-center gap-2"
                  >
                    <Bot className="w-5 h-5" />
                    <span>Join Waitlist</span>
                  </button>
                  {/* <button className="px-6 py-3 bg-white/10 hover:bg-white/20 text-white font-mono rounded-lg transition-colors backdrop-blur-sm border border-white/30 flex items-center gap-2">
                    <Terminal className="w-5 h-5" />
                    <span>See Demo</span>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <div className="border-b mt-8 border-gray-200">
          <div className="max-w-6xl mx-auto px-4">
            <nav className="flex overflow-x-auto py-4 gap-8">
              {["overview", "features", "setup", "analytics", "pricing"].map(
                (tab) => (
                  <button
                    key={tab}
                    onClick={() => setActiveTab(tab)}
                    className={`py-2 px-1 font-mono whitespace-nowrap capitalize text-sm transition-colors ${
                      activeTab === tab
                        ? "text-blue-600 border-b-2 border-blue-600"
                        : "text-gray-600 hover:text-blue-600"
                    }`}
                  >
                    {tab}
                  </button>
                )
              )}
            </nav>
          </div>
        </div>

        {/* Main Content Based on Active Tab */}
        <div className="py-12 max-w-6xl mx-auto px-4">
          {activeTab === "overview" && (
            <div className="space-y-16">
              {/* Value Proposition */}
              <div className="text-center mb-16">
                <h2 className="font-mono text-3xl font-bold mb-6">
                  The Future of Real Estate & Hospitality Operations
                </h2>
                <p className="text-gray-600 max-w-3xl mx-auto">
                  Our AI agents seamlessly blend into your team, performing
                  complex tasks autonomously, learning from interactions, and
                  delivering exceptional experiences to your clients and guests.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
                  <div className="p-6 bg-gray-50 rounded-xl">
                    <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                      <Zap className="w-6 h-6 text-blue-600" />
                    </div>
                    <h3 className="font-mono font-bold text-lg mb-2">
                      24/7 Availability
                    </h3>
                    <p className="text-gray-600 text-sm">
                      Never miss an opportunity with agents that work around the
                      clock, providing instant responses and handling tasks at
                      any hour.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-xl">
                    <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                      <Brain className="w-6 h-6 text-blue-600" />
                    </div>
                    <h3 className="font-mono font-bold text-lg mb-2">
                      Continuous Learning
                    </h3>
                    <p className="text-gray-600 text-sm">
                      Agents improve over time by learning from every
                      interaction, adapting to your business needs and customer
                      preferences.
                    </p>
                  </div>

                  <div className="p-6 bg-gray-50 rounded-xl">
                    <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mx-auto mb-4">
                      <Workflow className="w-6 h-6 text-blue-600" />
                    </div>
                    <h3 className="font-mono font-bold text-lg mb-2">
                      Seamless Integration
                    </h3>
                    <p className="text-gray-600 text-sm">
                      Connect with your existing systems and workflows, creating
                      a unified ecosystem that eliminates data silos.
                    </p>
                  </div>
                </div>
              </div>

              {/* Showcase Specific Agents */}
              <div>
                <h2 className="font-mono text-2xl font-bold mb-8 text-center">
                  Meet Our Specialized AI Agents
                </h2>

                <div className="space-y-12">
                  {agents.map((agent, index) => (
                    <div
                      key={agent.id}
                      className={`flex flex-col ${
                        index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                      } gap-8 items-center`}
                    >
                      <div className="w-full md:w-5/12">
                        <div className="bg-blue-50 aspect-video rounded-xl overflow-hidden p-6 flex items-center justify-center relative">
                          <agent.icon
                            className={`w-16 h-16 ${agent.iconColor}`}
                          />
                          <div className="absolute inset-0 bg-gradient-to-tr from-blue-500/5 to-transparent"></div>

                          {/* Animated dots showing agent working */}
                          <div className="absolute bottom-6 right-6 flex gap-1">
                            <span className="w-2 h-2 bg-blue-600 rounded-full animate-ping-slow"></span>
                            <span className="w-2 h-2 bg-blue-600 rounded-full animate-ping-slower delay-200"></span>
                            <span className="w-2 h-2 bg-blue-600 rounded-full animate-ping-slow delay-500"></span>
                          </div>
                        </div>
                      </div>

                      <div className="w-full md:w-7/12">
                        <div className="flex items-center gap-3 mb-3">
                          <Badge status={agent.status} />
                          <div className="text-xs text-gray-500 font-mono">
                            agent_{agent.id}
                          </div>
                        </div>

                        <h3 className="font-mono text-2xl font-bold mb-3">
                          {agent.name}
                        </h3>
                        <p className="text-gray-600 mb-6">
                          {agent.description}
                        </p>

                        <div className="flex flex-wrap gap-2 mb-6">
                          {agent.tags.map((tag) => (
                            <span
                              key={tag}
                              className="bg-gray-100 px-3 py-1 rounded-full text-xs font-mono"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>

                        <button className="flex items-center gap-2 text-blue-600 font-mono text-sm group">
                          <span>View agent details</span>
                          <ChevronRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeTab === "features" && (
            <div className="space-y-16">
              <h2 className="font-mono text-3xl font-bold mb-8 text-center">
                Powerful Capabilities
              </h2>

              {/* Feature Groups */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                {/* Conversational Group */}
                <div className="space-y-6">
                  <div className="w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center">
                    <MessageSquare className="w-6 h-6 text-green-600" />
                  </div>
                  <h3 className="font-mono text-xl font-bold">
                    Natural Conversations
                  </h3>
                  <p className="text-gray-600">
                    Our agents communicate naturally, understanding context,
                    nuance, and intent to provide human-like interactions that
                    build trust with your clients and guests.
                  </p>

                  <ul className="space-y-3 mt-6">
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-green-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Multi-turn context awareness
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-green-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Sentiment analysis and emotional intelligence
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-green-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Multilingual support for global clients
                      </span>
                    </li>
                  </ul>
                </div>

                {/* Autonomous Decision Making */}
                <div className="space-y-6">
                  <div className="w-12 h-12 bg-amber-100 rounded-lg flex items-center justify-center">
                    <Brain className="w-6 h-6 text-amber-600" />
                  </div>
                  <h3 className="font-mono text-xl font-bold">
                    Autonomous Decision Making
                  </h3>
                  <p className="text-gray-600">
                    Agents analyze information, make informed decisions, and
                    take action within the boundaries you define, freeing your
                    team from repetitive tasks.
                  </p>

                  <ul className="space-y-3 mt-6">
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-amber-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-amber-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Rule-based decision frameworks
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-amber-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-amber-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Human escalation protocols for complex issues
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-amber-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-amber-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Continuous learning from past interactions
                      </span>
                    </li>
                  </ul>
                </div>

                {/* Integration Capabilities */}
                <div className="space-y-6">
                  <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center">
                    <Workflow className="w-6 h-6 text-blue-600" />
                  </div>
                  <h3 className="font-mono text-xl font-bold">
                    Seamless Integrations
                  </h3>
                  <p className="text-gray-600">
                    Connect your agents with the tools and platforms you already
                    use, creating a unified ecosystem that keeps data flowing
                    smoothly.
                  </p>

                  <ul className="space-y-3 mt-6">
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-blue-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        API connections to popular CRMs and PMSs
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-blue-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Webhook support for custom workflows
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-blue-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Data synchronization across platforms
                      </span>
                    </li>
                  </ul>
                </div>

                {/* Analytics & Insights */}
                <div className="space-y-6">
                  <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center">
                    <AreaChart className="w-6 h-6 text-purple-600" />
                  </div>
                  <h3 className="font-mono text-xl font-bold">
                    Analytics & Insights
                  </h3>
                  <p className="text-gray-600">
                    Gain valuable business intelligence from every interaction,
                    with comprehensive dashboards that highlight trends,
                    opportunities, and areas for improvement.
                  </p>

                  <ul className="space-y-3 mt-6">
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-purple-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Performance metrics and KPI tracking
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-purple-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Customer sentiment analysis
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <div className="w-5 h-5 rounded-full bg-purple-100 flex items-center justify-center flex-shrink-0 mt-0.5">
                        <svg
                          className="w-3 h-3 text-purple-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <span className="text-sm text-gray-600">
                        Customizable reporting and export functions
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          {activeTab === "setup" && (
            <div className="space-y-16">
              <h2 className="font-mono text-3xl font-bold mb-8 text-center">
                Simple Setup, Powerful Results
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Step 1 */}
                <div className="relative">
                  <div className="absolute -left-4 top-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center font-mono font-bold text-blue-700">
                    1
                  </div>
                  <div className="pt-1 pl-6">
                    <h3 className="font-mono text-xl font-bold mb-4">
                      Configure Your Agent
                    </h3>
                    <img
                      src="/Configure-Agent.png"
                      alt="Configure Agent"
                      className="w-full h-48 object-cover rounded-lg mb-4 bg-gray-100"
                    />{" "}
                    {/* Replace with actual image */}
                    <p className="text-gray-600 text-sm">
                      Set up your agent's personality, knowledge base,
                      decision-making parameters, and integration points through
                      our intuitive interface.
                    </p>
                  </div>
                </div>

                {/* Step 2 */}
                <div className="relative">
                  <div className="absolute -left-4 top-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center font-mono font-bold text-blue-700">
                    2
                  </div>
                  <div className="pt-1 pl-6">
                    <h3 className="font-mono text-xl font-bold mb-4">
                      Train & Refine
                    </h3>
                    <img
                      src="/Train-Agent.png"
                      alt="Train Agent"
                      className="w-full h-48 object-cover rounded-lg mb-4 bg-gray-100"
                    />{" "}
                    {/* Replace with actual image */}
                    <p className="text-gray-600 text-sm">
                      Upload your business data, provide examples of ideal
                      interactions, and fine-tune your agent's responses to
                      align with your brand voice.
                    </p>
                  </div>
                </div>

                {/* Step 3 */}
                <div className="relative">
                  <div className="absolute -left-4 top-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center font-mono font-bold text-blue-700">
                    3
                  </div>
                  <div className="pt-1 pl-6">
                    <h3 className="font-mono text-xl font-bold mb-4">
                      Deploy & Monitor
                    </h3>
                    <img
                      src="/Deploy-Agent.png"
                      alt="Deploy Agent"
                      className="w-full h-48 object-cover rounded-lg mb-4 bg-gray-100"
                    />{" "}
                    {/* Replace with actual image */}
                    <p className="text-gray-600 text-sm">
                      Launch your agent across your desired channels, monitor
                      performance through our dashboard, and make continuous
                      improvements based on real interactions.
                    </p>
                  </div>
                </div>
              </div>

              {/* Setup Process Visualization */}
              <div className="bg-gray-50 p-8 rounded-xl">
                <h3 className="font-mono text-xl font-bold mb-6 text-center">
                  Agent Setup Process
                </h3>

                <div className="relative">
                  {/* Process Timeline */}
                  <div className="absolute left-8 top-0 bottom-0 w-1 bg-blue-100 hidden md:block"></div>

                  <div className="space-y-12">
                    {/* Process Step 1 */}
                    <div className="flex flex-col md:flex-row gap-8">
                      <div className="w-full md:w-1/4 relative">
                        <div className="hidden md:block absolute left-8 top-6 w-4 h-4 bg-blue-600 rounded-full -ml-1.5"></div>
                        <h4 className="font-mono font-bold text-lg md:pl-16">
                          Define Objectives
                        </h4>
                      </div>
                      <div className="w-full md:w-3/4">
                        <p className="text-gray-600 mb-4">
                          Identify the specific tasks and workflows you want
                          your agent to handle. Set clear goals and success
                          metrics to measure your agent's performance.
                        </p>
                        <div className="bg-white p-4 rounded-lg border border-gray-100">
                          <div className="font-mono text-xs text-gray-500 mb-2">
                            Example Configuration:
                          </div>
                          <code className="text-xs text-blue-700 block overflow-x-auto">
                            {`{
  "objectives": ["Guest inquiry handling", "Booking management", "Upselling opportunities"],
  "success_metrics": {
    "response_time": "< 1 minute",
    "resolution_rate": "> 85%",
    "customer_satisfaction": "> 4.5/5"
  }
}`}
                          </code>
                        </div>
                      </div>
                    </div>

                    {/* Process Step 2 */}
                    <div className="flex flex-col md:flex-row gap-8">
                      <div className="w-full md:w-1/4 relative">
                        <div className="hidden md:block absolute left-8 top-6 w-4 h-4 bg-blue-600 rounded-full -ml-1.5"></div>
                        <h4 className="font-mono font-bold text-lg md:pl-16">
                          Connect Data Sources
                        </h4>
                      </div>
                      <div className="w-full md:w-3/4">
                        <p className="text-gray-600 mb-4">
                          Integrate your existing systems to provide your agent
                          with the information it needs to operate effectively,
                          from property listings to reservation systems.
                        </p>
                        <div className="grid grid-cols-3 gap-2">
                          <div className="bg-white p-3 rounded-lg border border-gray-100 flex items-center justify-center">
                            <img src="/CRM.png" alt="CRM" className="h-70" />{" "}
                            {/* Replace with actual logo */}
                          </div>
                          <div className="bg-white p-3 rounded-lg border border-gray-100 flex items-center justify-center">
                            <img
                              src="/calander.png"
                              alt="Calendar"
                              className="h-70"
                            />{" "}
                            {/* Replace with actual logo */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Process Step 3 */}
                    <div className="flex flex-col md:flex-row gap-8">
                      <div className="w-full md:w-1/4 relative">
                        <div className="hidden md:block absolute left-8 top-6 w-4 h-4 bg-blue-600 rounded-full -ml-1.5"></div>
                        <h4 className="font-mono font-bold text-lg md:pl-16">
                          Train Your Agent
                        </h4>
                      </div>
                      <div className="w-full md:w-3/4">
                        <p className="text-gray-600 mb-4">
                          Provide your agent with examples of ideal
                          interactions, upload your business data, and fine-tune
                          its responses to align with your brand voice.
                        </p>
                        <div className="bg-white p-4 rounded-lg border border-gray-100">
                          <div className="font-mono text-xs text-gray-500 mb-2">
                            Training Example:
                          </div>
                          <code className="text-xs text-blue-700 block overflow-x-auto">
                            {`{
  "training_data": [
    {"input": "Can I book a room for next weekend?", "output": "Sure! Let me check availability for you."},
    {"input": "What are your check-in times?", "output": "Check-in is at 3 PM. Let me know if you need early check-in!"}
  ]
}`}
                          </code>
                        </div>
                      </div>
                    </div>

                    {/* Process Step 4 */}
                    <div className="flex flex-col md:flex-row gap-8">
                      <div className="w-full md:w-1/4 relative">
                        <div className="hidden md:block absolute left-8 top-6 w-4 h-4 bg-blue-600 rounded-full -ml-1.5"></div>
                        <h4 className="font-mono font-bold text-lg md:pl-16">
                          Deploy & Monitor
                        </h4>
                      </div>
                      <div className="w-full md:w-3/4">
                        <p className="text-gray-600 mb-4">
                          Launch your agent across your desired channels and
                          monitor its performance through our comprehensive
                          dashboard.
                        </p>
                        <div className="bg-white p-4 rounded-lg border border-gray-100">
                          <div className="font-mono text-xs text-gray-500 mb-2">
                            Deployment Channels:
                          </div>
                          <div className="flex gap-2">
                            <span className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-xs">
                              Website
                            </span>
                            <span className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-xs">
                              WhatsApp
                            </span>
                            <span className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-xs">
                              Email
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === "analytics" && (
            <div className="space-y-16">
              <h2 className="font-mono text-3xl font-bold mb-8 text-center">
                Actionable Insights
              </h2>

              {/* Analytics Overview */}
              <div className="text-center max-w-2xl mx-auto">
                <p className="text-gray-600 mb-8">
                  Our AI agents provide real-time analytics and insights to help
                  you make data-driven decisions and continuously improve your
                  operations.
                </p>
              </div>

              {/* Analytics Features */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* Performance Metrics */}
                <div className="p-6 bg-gray-50 rounded-xl">
                  <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                    <AreaChart className="w-6 h-6 text-blue-600" />
                  </div>
                  <h3 className="font-mono text-xl font-bold mb-3">
                    Performance Metrics
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Track key metrics like response time, resolution rate, and
                    customer satisfaction to measure your agent's effectiveness.
                  </p>
                </div>

                {/* Customer Insights */}
                <div className="p-6 bg-gray-50 rounded-xl">
                  <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                    <Users className="w-6 h-6 text-blue-600" />
                  </div>
                  <h3 className="font-mono text-xl font-bold mb-3">
                    Customer Insights
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Understand customer preferences, behavior, and sentiment to
                    enhance their experience.
                  </p>
                </div>

                {/* Business Intelligence */}
                <div className="p-6 bg-gray-50 rounded-xl">
                  <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                    <BarChart className="w-6 h-6 text-blue-600" />
                  </div>
                  <h3 className="font-mono text-xl font-bold mb-3">
                    Business Intelligence
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Identify trends, opportunities, and areas for improvement
                    with comprehensive dashboards.
                  </p>
                </div>
              </div>

              {/* Analytics Dashboard Preview */}
              {/* <div className="bg-gray-50 p-8 rounded-xl">
                <h3 className="font-mono text-xl font-bold mb-6 text-center">
                  Analytics Dashboard
                </h3>
                <div className="bg-white p-6 rounded-lg border border-gray-100">
                  <img
                    src="/analytics-dashboard.jpg"
                    alt="Analytics Dashboard"
                    className="w-full h-auto rounded-lg"
                  />{" "}
                
                </div>
              </div> */}
            </div>
          )}

          {activeTab === "pricing" && (
            <div className="space-y-16">
              <h2 className="font-mono text-3xl font-bold mb-8 text-center">
                Flexible Pricing Plans
              </h2>

              {/* Pricing Overview */}
              <div className="text-center max-w-2xl mx-auto">
                <p className="text-gray-600 mb-8">
                  Choose a plan that fits your business needs. All plans include
                  full access to our AI agents, analytics, and support.
                </p>
              </div>

              {/* Pricing Cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {/* Starter Plan */}
                <div className="p-6 bg-gray-50 rounded-xl">
                  <h3 className="font-mono text-xl font-bold mb-4">Starter</h3>
                  <p className="text-gray-600 text-sm mb-6">
                    Perfect for small businesses looking to automate key
                    workflows.
                  </p>
                  <div className="font-mono text-3xl font-bold mb-6">
                    R5,000<span className="text-sm text-gray-500">/month</span>
                  </div>
                  <ul className="space-y-3 mb-6">
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">1 AI Agent</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Basic Analytics
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Email Support
                      </span>
                    </li>
                  </ul>
                  <button className="w-full bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-lg font-mono transition-colors">
                    Get Started
                  </button>
                </div>

                {/* Pro Plan */}
                <div className="p-6 bg-gray-50 rounded-xl border-2 border-blue-600">
                  <h3 className="font-mono text-xl font-bold mb-4">Pro</h3>
                  <p className="text-gray-600 text-sm mb-6">
                    Ideal for growing businesses with more complex automation
                    needs.
                  </p>
                  <div className="font-mono text-3xl font-bold mb-6">
                    R20,000<span className="text-sm text-gray-500">/month</span>
                  </div>
                  <ul className="space-y-3 mb-6">
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Up to 3 AI Agents
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Advanced Analytics
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Priority Support
                      </span>
                    </li>
                  </ul>
                  <button className="w-full bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg font-mono transition-colors">
                    Get Started
                  </button>
                </div>

                {/* Enterprise Plan */}
                <div className="p-6 bg-gray-50 rounded-xl">
                  <h3 className="font-mono text-xl font-bold mb-4">
                    Enterprise
                  </h3>
                  <p className="text-gray-600 text-sm mb-6">
                    Custom solutions for large businesses with unique
                    requirements.
                  </p>
                  <div className="font-mono text-3xl font-bold mb-6">
                    Custom
                  </div>
                  <ul className="space-y-3 mb-6">
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Unlimited AI Agents
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Custom Analytics
                      </span>
                    </li>
                    <li className="flex items-start gap-2">
                      <CheckCircle2 className="w-4 h-4 text-blue-600 mt-0.5" />
                      <span className="text-sm text-gray-600">
                        Dedicated Support
                      </span>
                    </li>
                  </ul>
                  <button className="w-full bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-lg font-mono transition-colors">
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Suggest New Agent Section */}
      <div className="bg-gray-50 p-8 sm:p-12 rounded-xl mt-12">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="font-mono text-2xl sm:text-3xl font-bold mb-4">
            Need a Custom AI Agent?
          </h2>
          <p className="text-gray-600 mb-8">
            Share your challenges, and we'll build an AI agent tailored to your
            needs.
          </p>
          <button
            onClick={() => setShowAgentForm(true)}
            className="bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-lg font-mono transition-colors flex items-center justify-center gap-2"
          >
            <Lightbulb className="w-5 h-5" />
            <span>Suggest an Agent</span>
          </button>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="mt-12">
        <FaqSection faqs={agentFaqs} />
      </div>

      {/* Footer */}
      <Footer />
      <WaitlistModal
        isOpen={showWaitlistModal}
        onClose={() => setShowWaitlistModal(false)}
      />
    </div>
  );
};

export default AgentsPage;
