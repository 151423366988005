// Sign Up Page Component
import React, { useState } from "react";
import {
  Bot,
  ChevronRight,
  Lock,
  Mail,
  Terminal,
  ArrowRight,
  Eye,
  EyeOff,
  X,
  CheckCircle2,
  User,
} from "lucide-react";
import { Link } from "react-router-dom";

export const SignUp = () => {
  const [method, setMethod] = useState("email");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);

  const otpInputRefs = Array(6)
    .fill(0)
    .map(() => React.createRef());

  const handleSendOTP = (e) => {
    e.preventDefault();
    if (!email || !fullName) return;

    setLoading(true);
    // Simulate OTP sending
    setTimeout(() => {
      setOtpSent(true);
      setLoading(false);
      // Focus first OTP input after a brief delay
      setTimeout(() => {
        otpInputRefs[0].current?.focus();
      }, 100);
    }, 1500);
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    // Only allow numbers
    if (value && !/^\d+$/.test(value)) return;

    // Update OTP array
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1);
    setOtp(newOtp);

    // Auto-focus next input if value is entered
    if (value && index < 5) {
      otpInputRefs[index + 1].current?.focus();
    }

    // Check if all fields are filled
    if (newOtp.every((digit) => digit) && newOtp.join("").length === 6) {
      verifyOtp(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle backspace
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      otpInputRefs[index - 1].current?.focus();
    }
  };

  const verifyOtp = (code) => {
    setLoading(true);
    // Simulate verification
    setTimeout(() => {
      setVerified(true);
      setLoading(false);
      // Redirect after verification
      setTimeout(() => {
        window.location.href = "/welcome";
      }, 1500);
    }, 1500);
  };

  const handleGoogleSignUp = () => {
    // Google authentication logic would go here
    console.log("Google sign up");
  };

  // Reset OTP form
  const resetOtp = () => {
    setOtpSent(false);
    setOtp(["", "", "", "", "", ""]);
  };

  return (
    <div className="min-h-screen flex flex-col justify-center bg-gradient-to-b from-gray-50 to-white py-12 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
      {/* Background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div
          className="absolute inset-0 opacity-[0.03]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
            backgroundSize: "60px 60px",
          }}
        ></div>
        <div className="absolute -top-24 -right-24 w-96 h-96 bg-blue-100 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-indigo-100 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
      </div>

      {/* Logo */}
      <div className="sm:mx-auto sm:w-full sm:max-w-md text-center mb-6">
        <div className="inline-flex items-center justify-center">
          <div className="relative">
            <img src="/logo-NoB.png" alt="AAAGentive" className="h-12 w-auto" />
            <div className="absolute top-0 right-0 h-3 w-3 bg-blue-500 rounded-full opacity-75 animate-ping"></div>
          </div>
          <h2 className="ml-3 text-2xl font-bold tracking-tight text-gray-900 font-mono">
            AAAGentive<span className="text-blue-600 animate-blink">_</span>
          </h2>
        </div>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-sm sm:rounded-2xl sm:px-10 border border-gray-100 relative">
          {/* Command bar header */}
          <div className="absolute top-0 inset-x-0 h-2 bg-gradient-to-r from-blue-500 to-indigo-500 sm:rounded-t-2xl"></div>

          <div className="mb-6 mt-4">
            <h2 className="text-xl font-semibold text-gray-900 font-mono flex items-center">
              <Terminal className="h-5 w-5 text-blue-500 mr-2" />
              {verified
                ? "account.created"
                : otpSent
                ? "auth.verify"
                : "account.create"}
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              {verified
                ? "Your account has been created successfully"
                : otpSent
                ? "Verify your email to continue"
                : "Join AAAGentive to streamline your business"}
            </p>
          </div>

          {verified ? (
            <div className="text-center py-8">
              <div className="inline-flex items-center justify-center h-16 w-16 rounded-full bg-green-100 mb-6">
                <CheckCircle2 className="h-8 w-8 text-green-600" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                Account Created Successfully
              </h3>
              <p className="text-sm text-gray-500 mb-6">
                Setting up your environment, please wait...
              </p>
              <div className="inline-flex items-center justify-center">
                <span className="inline-block h-2 w-2 rounded-full bg-blue-600 animate-ping mr-2"></span>
                <span className="text-xs font-mono text-gray-500">
                  initialize_account()
                </span>
              </div>
            </div>
          ) : otpSent ? (
            <div>
              <div className="mb-4 flex items-center justify-between">
                <p className="text-sm text-gray-600 font-mono">{email}</p>
                <button
                  onClick={resetOtp}
                  className="text-sm text-blue-600 hover:text-blue-500 font-medium flex items-center"
                >
                  <X className="h-4 w-4 mr-1" />
                  Change
                </button>
              </div>

              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Verification Code
                  </label>
                  <div className="flex gap-2 justify-center">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={otpInputRefs[index]}
                        type="text"
                        inputMode="numeric"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        className="w-12 h-12 text-center border border-gray-200 rounded-md text-lg font-mono focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors"
                      />
                    ))}
                  </div>
                  <p className="mt-2 text-xs text-gray-500 text-center">
                    Didn't receive a code?{" "}
                    <button className="text-blue-600 hover:text-blue-500">
                      Resend
                    </button>
                  </p>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={loading || otp.join("").length !== 6}
                    className={`w-full flex justify-center py-2.5 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none transition-colors ${
                      loading || otp.join("").length !== 6
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    {loading ? (
                      <span className="inline-block h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                    ) : (
                      <span className="flex items-center font-mono">
                        <Bot className="h-4 w-4 mr-2" />
                        create_account()
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <>
              {/* Auth method tabs */}
              <div className="flex border-b border-gray-100 mb-6">
                <button
                  className={`flex-1 py-3 text-sm font-medium text-center border-b-2 transition-colors ${
                    method === "email"
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => setMethod("email")}
                >
                  Email
                </button>
                <button
                  className={`flex-1 py-3 text-sm font-medium text-center border-b-2 transition-colors ${
                    method === "google"
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => setMethod("google")}
                >
                  Google
                </button>
              </div>

              {method === "email" ? (
                <form className="space-y-6" onSubmit={handleSendOTP}>
                  <div>
                    <label
                      htmlFor="fullName"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Full Name
                    </label>
                    <div className="relative">
                      <input
                        id="fullName"
                        name="fullName"
                        type="text"
                        autoComplete="name"
                        required
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        className="appearance-none block w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors pl-10"
                        placeholder="Your full name"
                      />
                      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                        <User className="h-5 w-5" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Email Address
                    </label>
                    <div className="relative">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="appearance-none block w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors pl-10"
                        placeholder="you@example.com"
                      />
                      <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                        <Mail className="h-5 w-5" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={loading || !email || !fullName}
                      className={`w-full flex justify-center py-2.5 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none transition-colors ${
                        loading || !email || !fullName
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      {loading ? (
                        <span className="inline-block h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                      ) : (
                        <span className="flex items-center font-mono">
                          <span>send_verification()</span>
                          <ArrowRight className="ml-2 h-4 w-4" />
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              ) : (
                <div className="space-y-6">
                  <button
                    onClick={handleGoogleSignUp}
                    className="w-full flex justify-center py-2.5 px-4 border border-gray-200 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none transition-colors"
                  >
                    <span className="flex items-center">
                      <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24">
                        <path
                          fill="#EA4335"
                          d="M5.26620003,9.76452941 C6.19878754,6.93863203 8.85444915,4.90909091 12,4.90909091 C13.6909091,4.90909091 15.2181818,5.50909091 16.4181818,6.49090909 L19.9090909,3 C17.7818182,1.14545455 15.0545455,0 12,0 C7.27006974,0 3.1977497,2.69829785 1.23999023,6.65002441 L5.26620003,9.76452941 Z"
                        />
                        <path
                          fill="#34A853"
                          d="M16.0407269,18.0125889 C14.9509167,18.7163129 13.5660892,19.0909091 12,19.0909091 C8.86648613,19.0909091 6.21911939,17.076871 5.27698177,14.2678769 L1.23746264,17.3349879 C3.19279051,21.2936293 7.26500293,24 12,24 C14.9328362,24 17.7353462,22.9573905 19.834192,20.9995801 L16.0407269,18.0125889 Z"
                        />
                        <path
                          fill="#4A90E2"
                          d="M19.834192,20.9995801 C22.0291676,18.9520994 23.4545455,15.903663 23.4545455,12 C23.4545455,11.2909091 23.3454545,10.5818182 23.1454545,9.90909091 L12,9.90909091 L12,14.7272727 L18.4363636,14.7272727 C18.1187732,16.013119 17.2662994,17.2544779 16.0407269,18.0125889 L19.834192,20.9995801 Z"
                        />
                        <path
                          fill="#FBBC05"
                          d="M5.27698177,14.2678769 C5.03832634,13.556323 4.90909091,12.7937589 4.90909091,12 C4.90909091,11.2182781 5.03443647,10.4668121 5.26620003,9.76452941 L1.23999023,6.65002441 C0.43658717,8.26043162 0,10.0753848 0,12 C0,13.9195484 0.444780743,15.7301709 1.23746264,17.3349879 L5.27698177,14.2678769 Z"
                        />
                      </svg>
                      Sign up with Google
                    </span>
                  </button>
                </div>
              )}
            </>
          )}

          <div className="mt-6 flex items-center justify-center">
            <span className="text-sm text-gray-500">
              Already have an account?
            </span>
            <Link
              to="/login"
              className="ml-1 text-sm font-medium text-blue-600 hover:text-blue-500 flex items-center"
            >
              Login
              <ChevronRight className="h-4 w-4 ml-0.5" />
            </Link>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-8 text-center">
        <p className="text-xs text-gray-500">
          By creating an account, you agree to our{" "}
          <a href="/terms" className="text-blue-600 hover:text-blue-500">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="/privacy" className="text-blue-600 hover:text-blue-500">
            Privacy Policy
          </a>
        </p>
      </div>

      {/* Animation styles */}
      <style jsx>{`
        @keyframes blink {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
        }

        .animate-blink {
          animation: blink 1s infinite;
        }

        @keyframes blob {
          0% {
            transform: scale(1);
          }
          33% {
            transform: scale(1.1);
          }
          66% {
            transform: scale(0.9);
          }
          100% {
            transform: scale(1);
          }
        }

        .animate-blob {
          animation: blob 7s infinite;
        }

        .animation-delay-2000 {
          animation-delay: 2s;
        }
      `}</style>
    </div>
  );
};

export default SignUp;
