import {
  Sparkles,
  Bot,
  Mic,
  Target,
  Mail,
  Pencil,
  Search,
  Smartphone,
  BarChart,
  PenTool,
  Video,
  PhoneCall,
  Home,
  UserRound,
  Wrench,
  // Bot,
  MessageSquareText,
} from "lucide-react";

export const agents = [
  {
    id: "property-match",
    name: "PropertyMatch AI",
    description:
      "Your 24/7 real estate agent that understands client needs, autonomously searches properties, schedules viewings, and manages the entire journey from inquiry to closing. Handles multiple clients simultaneously while learning preferences.",
    icon: Home,
    iconBg: "bg-amber-50",
    iconColor: "text-amber-600",
    tags: ["Real Estate", "Lead Generation", "Sales"],
    status: "beta",
  },
  {
    id: "guest-flow",
    name: "GuestFlow AI",
    description:
      "A conversational AI that manages the entire guest journey, from pre-arrival to post-stay. It sends personalized check-in instructions, handles room service requests, and proactively resolves issues during the stay. Post-checkout, it sends thank-you notes and requests reviews, ensuring a seamless and memorable experience.",
    icon: UserRound,
    iconBg: "bg-green-50",
    iconColor: "text-green-600",
    tags: ["Hospitality", "Guest Experience", "Retention"],
    status: "beta",
  },
  {
    id: "maintenance-mate",
    name: "MaintenanceMate AI",
    description:
      "A property maintenance assistant for real estate and hospitality. It schedules routine maintenance, tracks repair requests, and ensures compliance with safety standards. For hotels, it manages housekeeping schedules and ensures rooms are ready on time.",
    icon: Wrench,
    iconBg: "bg-orange-50",
    iconColor: "text-orange-600",
    tags: ["Real Estate", "Hospitality", "Maintenance"],
    status: "alpha",
  },
];

export const salesAgents = [
  {
    title: "Sales Representative AI",
    description:
      "Conducts product demonstrations and handles customer inquiries autonomously.",
    icon: <Bot className="w-6 h-6 text-blue-400" />,
    gradient: "from-blue-500/20 to-cyan-500/20",
    accent: "blue",
    originalIcon: "🤖",
  },
  {
    title: "Sales Call Analyzer",
    description:
      "Transcribes and analyzes sales calls in real-time, providing insights for improvement.",
    icon: <Mic className="w-6 h-6 text-green-400" />,
    gradient: "from-green-500/20 to-teal-500/20",
    accent: "green",
    originalIcon: "🎙️",
  },
  {
    title: "Lead Generation Assistant",
    description:
      "Identifies and qualifies potential leads by analyzing online behavior and industry trends.",
    icon: <Target className="w-6 h-6 text-red-400" />,
    gradient: "from-red-500/20 to-orange-500/20",
    accent: "red",
    originalIcon: "🎯",
  },
  {
    title: "Outreach Optimizer",
    description:
      "Crafts personalized email campaigns and optimizes content for maximum engagement.",
    icon: <Mail className="w-6 h-6 text-indigo-400" />,
    gradient: "from-indigo-500/20 to-purple-500/20",
    accent: "indigo",
    originalIcon: "📧",
  },
];

export const marketingAgents = [
  {
    title: "Social Media Storyteller AI",
    description:
      "Generates human-like, natural-sounding content for LinkedIn, Twitter, and TikTok scripts. Tailors content to your brand’s voice and optimizes for engagement.",
    icon: <PenTool className="w-6 h-6 text-purple-400" />,
    gradient: "from-purple-500/20 to-pink-500/20",
    accent: "purple",
    originalIcon: "✍️",
    keyFeatures: [
      "Generates human-like content for social media",
      "Tailors content to brand voice and audience",
      "Optimizes posts for engagement (hashtags, trends)",
      "Supports AI video generation for TikTok scripts",
    ],
    whyImportant:
      "Saves time for founders, ensures consistent high-quality content, and builds organic reach.",
    status: "Prototype in Progress",
  },
  {
    title: "Auto-Demo Pro",
    description:
      "Automates product demos via Zoom or Teams, handles real-time Q&A, and schedules follow-ups.",
    icon: <Video className="w-6 h-6 text-blue-400" />,
    gradient: "from-blue-500/20 to-cyan-500/20",
    accent: "blue",
    originalIcon: "📹",
    keyFeatures: [
      "Automates product demos and schedules meetings",
      "Real-time Q&A with deep product knowledge",
      "Handles objections and delegates to humans",
      "24/7 availability for global customers",
    ],
    whyImportant:
      "Reduces the need for human sales reps, increases conversion rates, and provides 24/7 availability.",
    status: "Live",
  },
  {
    title: "Cold Call Outreach AI Agent",
    description:
      "Automates cold calling with natural, conversational AI. Personalizes calls and handles objections.",
    icon: <PhoneCall className="w-6 h-6 text-green-400" />,
    gradient: "from-green-500/20 to-teal-500/20",
    accent: "green",
    originalIcon: "📞",
    keyFeatures: [
      "Automates cold calling with conversational AI",
      "Personalizes calls based on prospect data",
      "Handles objections and schedules follow-ups",
      "Tracks performance with detailed analytics",
    ],
    whyImportant:
      "Eliminates the fear of manual cold calling, scales outreach efforts, and increases lead generation.",
    status: "Prototype in Progress",
  },
  {
    title: "Email Alchemist",
    description:
      "Writes personalized, high-converting emails and optimizes campaigns for higher engagement.",
    icon: <Mail className="w-6 h-6 text-orange-400" />,
    gradient: "from-orange-500/20 to-red-500/20",
    accent: "orange",
    originalIcon: "📧",
    keyFeatures: [
      "Writes personalized, high-converting emails",
      "Optimizes subject lines, body content, and CTAs",
      "A/B tests email versions for best performance",
      "Integrates with CRMs and automates follow-ups",
    ],
    whyImportant:
      "Saves time on email crafting, improves campaign ROI, and ensures consistent follow-ups.",
    status: "Live",
  },
];

export const blogPosts = [
  {
    title: "Deploying AI Agents for Modern Startups",
    excerpt:
      "Learn how to leverage AI agents to automate your workflow and scale your startup efficiently with minimal resources and maximum impact.",
    category: "AI",
    date: "Feb 2, 2025",
    image:
      "https://blog.ori.co/hs-fs/hubfs/The%20AI%20Native%20Cloud%20(2).png?width=2880&height=1440&name=The%20AI%20Native%20Cloud%20(2).png",
  },
  {
    title: "The Future of Marketing: AI-Driven Social Media Management",
    excerpt:
      "Discover how AI agents are revolutionizing social media management by creating engaging content and optimizing posting schedules autonomously.",
    category: "Marketing",
    date: "Jan 28, 2025",
    image:
      "https://images.prismic.io//intuzwebsite/ZrYQ5kaF0TcGI0pP_AIAgent%26AIAgenticWorkflowsAcrossIndustries-1.png?w=2400&q=80&auto=format,compress&fm=png8",
  },
  {
    title: "Building Your First Autonomous Sales Pipeline",
    excerpt:
      "Step-by-step guide to implementing AI agents that handle lead generation, qualification, and follow-ups while maintaining a personal touch.",
    category: "Sales",
    date: "Jan 25, 2025",
    image:
      "https://images.prismic.io//intuzwebsite/ZrYQ5kaF0TcGI0pP_AIAgent%26AIAgenticWorkflowsAcrossIndustries-1.png?w=2400&q=80&auto=format,compress&fm=png8",
  },
  {
    title: "AI Agents vs Traditional Automation: What's Different?",
    excerpt:
      "Understanding the key differences between AI agents and traditional automation tools, and when to use each for optimal business outcomes.",
    category: "Technology",
    date: "Jan 21, 2025",
    image:
      "https://s3-ap-south-1.amazonaws.com/trt-blog-ghost/2024/10/jpg-11.webp",
  },
  {
    title: "Scaling Customer Support with AI Agents",
    excerpt:
      "How solo founders and small teams can provide 24/7 customer support using intelligent AI agents that understand context and handle complex queries.",
    category: "Support",
    date: "Jan 18, 2025",
    image:
      "https://blog.ori.co/hs-fs/hubfs/The%20AI%20Native%20Cloud%20(2).png?width=2880&height=1440&name=The%20AI%20Native%20Cloud%20(2).png",
  },
  {
    title: "The ROI of Implementing AI Agents in Your Workflow",
    excerpt:
      "Real-world case studies and metrics showing the return on investment when implementing AI agents across different business functions.",
    category: "Business",
    date: "Jan 15, 2025",
    image:
      "https://s3-ap-south-1.amazonaws.com/trt-blog-ghost/2024/10/jpg-11.webp",
  },
];
