import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft, Calendar, Tag } from "lucide-react";
import { Helmet } from "react-helmet-async";
import BlogContent from "./BlogContent";
import Navigation from "../../navigation/Navigation";
import Footer from "../../components/footer/Footer";

const BlogPostPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

 
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://aaagentive-backend-v2.onrender.com/blogs/${id}`
        );
        if (!response.ok) throw new Error("Blog post not found");
        const data = await response.json();
        setPost(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [id]);

  const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <div className="flex flex-col items-center">
          <img
            src="/logo.png"
            alt="Loading"
            className="w-16 h-16 mb-4 animate-spin"
          />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        Error: {error}
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        Blog post not found
      </div>
    );
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: post.title,
    description: post.excerpt,
    image: post.cover_image_url,
    datePublished: post.date,
    author: {
      "@type": "Organization",
      name: "AAAGentive",
    },
    publisher: {
      "@type": "Organization",
      name: "AAAGentive",
      logo: {
        "@type": "ImageObject",
        url: "https://aaagentive.com/logo.png", // Replace with your logo URL
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>{post.title} | AAAGentive Blog</title>
        <meta name="description" content={post.excerpt} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.cover_image_url} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta name="twitter:image" content={post.cover_image_url} />

        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="min-h-screen bg-black text-white">
        <Navigation />

        <div className="relative h-[60vh] w-full">
          <div className="absolute inset-0">
            <img
              src={post.cover_image_url || "/api/placeholder/1200/800"}
              alt={post.title}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black"></div>
          </div>

          <div className="relative max-w-4xl mx-auto px-4 h-full flex flex-col justify-end pb-16">
            <button
              onClick={() => (window.location.href = "/blogs")}
              className="mb-8 flex items-center text-gray-300 hover:text-white transition-colors"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back to Blog
            </button>

            <div className="space-y-4">
              <div className="flex items-center gap-4 text-sm">
                <span className="flex items-center">
                  <Calendar className="w-4 h-4 mr-1" />
                  {formatDate(post.date)}
                </span>
                <span className="flex items-center">
                  <Tag className="w-4 h-4 mr-1" />
                  {post.category}
                </span>
              </div>
              <h1 className="text-4xl md:text-5xl font-bold">{post.title}</h1>
              <p className="text-xl text-gray-300">{post.excerpt}</p>
            </div>
          </div>
        </div>

        {/* Content Sections */}

        <main className="max-w-4xl mx-auto px-4 py-16">
          <div className="prose prose-invert prose-lg max-w-none">
            {post.sections.map((section) => (
              <BlogContent key={section.id} section={section} />
            ))}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default BlogPostPage;
