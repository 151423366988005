import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  X,
  ChevronDown,
  Bot,
  Workflow,
  FileText,
  Headset,
  Zap,
  Building,
  Briefcase,
} from "lucide-react";
import WaitlistModal from "../components/WaitlistModal/WaitlistModal";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [mobileSections, setMobileSections] = useState({
    solutions: false,
    resources: false,
  });
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleDropdown = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);
    }
  };

  const toggleMobileSection = (section) => {
    setMobileSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Solutions dropdown categorized for AI & Automation agency
  const solutions = [
    {
      name: "AI Agents",
      href: "/agents",
      icon: Bot,
      description: "Conversational AI assistants for business tasks",
    },
    {
      name: "Automation",
      href: "/automation",
      icon: Workflow,
      description: "Custom workflow automation solutions",
    },
    {
      name: "For Real Estate",
      href: "/real-estate",
      icon: Building,
      description: "Specialized solutions for property businesses",
    },
    {
      name: "For Recruitment",
      href: "/recruitment",
      icon: Briefcase,
      description: "Talent acquisition automation tools",
    },
  ];

  // Resources dropdown for documentation, guides, etc.
  const resources = [
    {
      name: "Documentation",
      href: "/docs",
      icon: FileText,
      description: "Technical guides and API references",
    },
    {
      name: "Case Studies",
      href: "/case-studies",
      icon: Zap,
      description: "Success stories from our clients",
    },
    {
      name: "Support",
      href: "/support",
      icon: Headset,
      description: "Get help with implementation",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset > 20);
    };

    const handleClickOutside = (e) => {
      if (
        !e.target.closest(".dropdown-menu") &&
        !e.target.closest(".dropdown-button")
      ) {
        setActiveDropdown(null);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav
        className={`w-full flex  justify-center px-3 py-2 z-40 transition-all duration-300 ${
          isSticky ? "sticky top-0 bg-white/80 backdrop-blur-md" : ""
        }`}
      >
        <div
          className={`w-full max-w-6xl bg-white rounded-xl shadow-sm border border-gray-100 transition-all duration-300 ${
            isSticky ? "py-2" : "py-3"
          }`}
        >
          <div className="mx-auto px-4">
            <div className="flex items-center justify-between h-14">
              {/* Logo */}
              <div className="flex items-center space-x-2">
                <Link to="/" className="flex items-center gap-2.5">
                  <div className="relative">
                    <img
                      src="/logo-NoB.png"
                      alt="AAAGentive"
                      className="w-8 h-8 sm:w-9 sm:h-9"
                    />
                    <div className="absolute -top-1 -right-1 h-2 w-2 bg-green-400 rounded-full animate-pulse hidden sm:block"></div>
                  </div>
                  <span className="text-lg sm:text-xl text-black font-bold font-mono tracking-tight">
                    AAAGentive<span className="text-blue-600">_</span>
                  </span>
                </Link>
              </div>

              {/* Mobile menu button */}
              <div className="flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none transition-colors"
                  aria-expanded={isOpen}
                >
                  <span className="sr-only">Open main menu</span>
                  {isOpen ? <X size={20} /> : <Menu size={20} />}
                </button>
              </div>

              {/* Desktop menu */}
              <div className="hidden md:flex md:items-center md:space-x-1 lg:space-x-4">
                {/* Solutions Dropdown */}
                {/* <div className="relative">
                  <button
                    onClick={() => toggleDropdown("solutions")}
                    className="dropdown-button flex items-center text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                  >
                    Solutions
                    <ChevronDown
                      size={16}
                      className={`ml-1.5 transition-transform ${
                        activeDropdown === "solutions" ? "rotate-180" : ""
                      }`}
                    />
                  </button>

                  {activeDropdown === "solutions" && (
                    <div className="dropdown-menu absolute z-50 mt-1 w-64 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right overflow-hidden">
                      <div className="py-1">
                        {solutions.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="group flex items-start gap-3 px-4 py-3 text-sm text-gray-700 hover:bg-gray-50 transition-colors"
                          >
                            <div className="mt-0.5 w-7 h-7 rounded-md bg-blue-50 flex items-center justify-center flex-shrink-0 group-hover:bg-blue-100 transition-colors">
                              <item.icon className="w-4 h-4 text-blue-600" />
                            </div>
                            <div>
                              <p className="font-medium">{item.name}</p>
                              <p className="text-xs text-gray-500 mt-0.5">
                                {item.description}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div> */}

                {/* Resources Dropdown */}
                {/* <div className="relative">
                  <button
                    onClick={() => toggleDropdown("resources")}
                    className="dropdown-button flex items-center text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                  >
                    Resources
                    <ChevronDown
                      size={16}
                      className={`ml-1.5 transition-transform ${
                        activeDropdown === "resources" ? "rotate-180" : ""
                      }`}
                    />
                  </button>

                  {activeDropdown === "resources" && (
                    <div className="dropdown-menu absolute z-50 mt-1 w-64 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {resources.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="group flex items-start gap-3 px-4 py-3 text-sm text-gray-700 hover:bg-gray-50 transition-colors"
                          >
                            <div className="mt-0.5 w-7 h-7 rounded-md bg-blue-50 flex items-center justify-center flex-shrink-0 group-hover:bg-blue-100 transition-colors">
                              <item.icon className="w-4 h-4 text-blue-600" />
                            </div>
                            <div>
                              <p className="font-medium">{item.name}</p>
                              <p className="text-xs text-gray-500 mt-0.5">
                                {item.description}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div> */}

                {/* AI agents Link */}
                <Link
                  to="/agents"
                  className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                >
                  Agents
                </Link>

                {/* Automation Link */}
                <Link
                  to="/services"
                  className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                >
                  Automation Services
                </Link>

                {/* Blog Link */}
                {/* <Link
                  to="/blogs"
                  className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                >
                  Blog
                </Link> */}

                {/* Contact button - shows in desktop only */}
                <Link
                  to="/contact"
                  className="hidden lg:block text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors"
                >
                  Contact
                </Link>

                {/* Authentication */}
                {/* <div className="flex items-center pl-1 lg:pl-4 ml-1 lg:ml-3 border-l border-gray-100">
      <Link
        to="/login"
        className="px-3 py-1.5 text-sm text-gray-700 hover:text-blue-600 font-medium transition-colors"
      >
        Login
      </Link>
      <Link
        to="/signup"
        className="ml-2 px-4 py-1.5 bg-black hover:bg-gray-800 text-white text-sm font-medium rounded-md transition-colors"
      >
        Sign up
      </Link>
    </div> */}
                <div className="flex items-center pl-1 lg:pl-4 ml-1 lg:ml-3 border-l border-gray-100">
                  <button
                    onClick={() => setShowWaitlistModal(true)}
                    className="px-4 py-1.5 bg-black hover:bg-gray-800 text-white text-sm font-medium rounded-md transition-colors flex items-center gap-2"
                  >
                    <Bot className="w-4 h-4" />
                    <span>Join Waitlist</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
              <div className="md:hidden pt-2 pb-4 border-t border-gray-100 mt-2">
                <div className="space-y-1 px-2">
                  {/* Mobile Solutions accordion */}
                  {/* <div>
                    <button
                      onClick={() => toggleMobileSection("solutions")}
                      className="w-full flex items-center justify-between px-3 py-2 text-base text-gray-700 hover:bg-gray-50 rounded-md"
                    >
                      <div className="flex items-center">
                        <Workflow className="w-5 h-5 mr-3 text-gray-400" />
                        <span>Solutions</span>
                      </div>
                      <ChevronDown
                        size={18}
                        className={`transition-transform ${
                          mobileSections.solutions ? "rotate-180" : ""
                        }`}
                      />
                    </button>

                    {mobileSections.solutions && (
                      <div className="mt-1 pl-12 space-y-1">
                        {solutions.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="block px-3 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-md"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div> */}

                  {/* Mobile Resources accordion */}
                  {/* <div>
                    <button
                      onClick={() => toggleMobileSection("resources")}
                      className="w-full flex items-center justify-between px-3 py-2 text-base text-gray-700 hover:bg-gray-50 rounded-md"
                    >
                      <div className="flex items-center">
                        <FileText className="w-5 h-5 mr-3 text-gray-400" />
                        <span>Resources</span>
                      </div>
                      <ChevronDown
                        size={18}
                        className={`transition-transform ${
                          mobileSections.resources ? "rotate-180" : ""
                        }`}
                      />
                    </button>

                    {mobileSections.resources && (
                      <div className="mt-1 pl-12 space-y-1">
                        {resources.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="block px-3 py-2 text-sm text-gray-600 hover:bg-gray-50 rounded-md"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div> */}

                  {/* Other mobile links */}
                  {/* <Link
                    to="/blog"
                    className="flex items-center px-3 py-2 text-base text-gray-700 hover:bg-gray-50 rounded-md"
                  >
                    <FileText className="w-5 h-5 mr-3 text-gray-400" />
                    Blog
                  </Link> */}

                  <Link
                    to="/agents"
                    className="flex items-center px-3 py-2 text-base text-gray-700 hover:bg-gray-50 rounded-md"
                  >
                    <Bot className="w-5 h-5 mr-3 text-gray-400" />
                    Agents
                  </Link>
                  <Link
                    to="/services"
                    className="flex items-center px-3 py-2 text-base text-gray-700 hover:bg-gray-50 rounded-md"
                  >
                    <Workflow className="w-5 h-5 mr-3 text-gray-400" />
                    Automation Services
                  </Link>

                  <Link
                    to="/contact"
                    className="flex items-center px-3 py-2 text-base text-gray-700 hover:bg-gray-50 rounded-md"
                  >
                    <Headset className="w-5 h-5 mr-3 text-gray-400" />
                    Contact
                  </Link>

                  {/* Mobile authentication */}
                  {/* <div className="mt-4 space-y-2 pt-4 border-t border-gray-100">
                    <Link
                      to="/login"
                      className="block w-full px-4 py-2 text-center text-blue-600 border border-blue-200 rounded-md hover:bg-blue-50 transition-colors"
                    >
                      Login
                    </Link>
                    <Link
                      to="/signup"
                      className="block w-full px-4 py-2 text-center bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
                    >
                      Sign up
                    </Link>
                  </div> */}
                  <div className="flex items-center pt-8 pl-1 lg:pl-4 ml-1 lg:ml-3 border-l border-gray-100">
                    <button
                      onClick={() => setShowWaitlistModal(true)}
                      className="px-4 py-1.5 bg-black hover:bg-gray-800 text-white text-sm font-medium rounded-md transition-colors flex items-center gap-2"
                    >
                      <Bot className="w-4 h-4" />
                      <span>Join Waitlist</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      <WaitlistModal
        isOpen={showWaitlistModal}
        onClose={() => setShowWaitlistModal(false)}
      />
    </>
  );
};

export default Navigation;
