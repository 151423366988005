// import React from "react";
// import Hero from "../components/hero/Hero";
// import HeroSection from "../components/hero/HeroSection";
// // import Navigation from "../navigation/Navigation";
// // import AgentCluster from "../components/AgentCluster/AgentCluster";
// import ClusterAgentsSlider from "../components/AgentCluster/ClusterAgentsSlider";
// import AgentGrid from "../components/AgentCluster/AgentGrid";
// // import { salesAgents, marketingAgents, blogPosts } from "../utils/AgentsData";
// import BlogSection from "../components/BlogSection/BlogSection";
// import Footer from "../components/footer/Footer";
// import TabNavigation from "../components/product_service/TabNavigation";
// import Navigation from "../navigation/Navigation";
// import AgentDetailTabs from "../components/product_service/AgentDetailTabs";
// import AgentsShowcase from "../components/product_service/AgentsShowcase";
// import FaqSection from "../components/FaqSection/FaqSection";
// import RoadmapSection from "../components/RoadmapSection/RoadmapSection";
// import CustomAgentRequestForm from "../components/CustomAgentRequestForm/CustomAgentRequestForm";
// import HeroSectionLatest from "../components/hero/HeroSectionLatest";
// import DeepHeroSection from "../components/hero/DeepHeroSection";
// import ProcessSection from "../components/ProcessSection/ProcessSection";
// import PricingSection from "../components/PricingSection/PricingSection";
// import AboutSection from "../components/AboutSection/AboutSection";

// function Home() {
//   return (
//     <>
//       <div className="bg-black">
//         <Navigation />
//         <HeroSection />
//         <AgentDetailTabs />
//         <ProcessSection />
//         <AboutSection />
//         <PricingSection />
//         <CustomAgentRequestForm />
//         <FaqSection />
//         <Footer />
//       </div>
//     </>
//   );
// }

// export default Home;
import React from "react";
import Hero from "../components/hero/Hero";
import HeroSection from "../components/hero/HeroSection";
import ClusterAgentsSlider from "../components/AgentCluster/ClusterAgentsSlider";
import AgentGrid from "../components/AgentCluster/AgentGrid";
import BlogSection from "../components/BlogSection/BlogSection";
import Footer from "../components/footer/Footer";
import TabNavigation from "../components/product_service/TabNavigation";
import NavigationLatest from "../navigation/NavigationLatest";
import AgentDetailTabs from "../components/product_service/AgentDetailTabs";
import AgentsShowcase from "../components/product_service/AgentsShowcase";
import FaqSection from "../components/FaqSection/FaqSection";
import RoadmapSection from "../components/RoadmapSection/RoadmapSection";
import CustomAgentRequestForm from "../components/CustomAgentRequestForm/CustomAgentRequestForm";
import HeroSectionLatest from "../components/hero/HeroSectionLatest";
import DeepHeroSection from "../components/hero/DeepHeroSection";
import ProcessSection from "../components/ProcessSection/ProcessSection";
import PricingSection from "../components/PricingSection/PricingSection";
import AboutSection from "../components/AboutSection/AboutSection";
import StrategicPricingSection from "../components/StrategicPricingSection/StrategicPricingSection";
import CustomServicesSection from "../components/CustomServicesSection/CustomServicesSection";

function Home() {
  return (
    <>
      <div className="bg-black">
        <NavigationLatest />

        {/* Add IDs to each section for scroll functionality */}
        <div id="hero-section">
          <HeroSection />
        </div>

        <div id="agents-section">
          <AgentDetailTabs />
        </div>

        <div id="services-section">
          <CustomServicesSection />
        </div>

        <div id="process-section">
          <ProcessSection />
        </div>

        <div id="about-section">
          <AboutSection />
        </div>

        <div id="pricing-section">
          <StrategicPricingSection />
        </div>

        <div id="contact-section">
          <CustomAgentRequestForm />
        </div>

        <div id="faq-section">
          <FaqSection />
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Home;
