import React, { useEffect, useState } from "react";
import {
  Terminal,
  Bot,
  Cpu,
  Workflow,
  Code,
  ArrowRight,
  Zap,
} from "lucide-react";
import WaitlistModal from "../WaitlistModal/WaitlistModal";

const HeroSection = () => {
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  return (
    <>
      <div className="flex justify-center items-center min-h-screen">
        <div className="relative min-h-screen w-[99%] md:w-[99%] lg:w-[99%] bg-white flex items-center justify-center overflow-hidden rounded-2xl">
          {/* Enhanced Background Elements */}
          <div className="absolute inset-0 flex items-center justify-center opacity-5">
            <div className="w-64 h-64 md:w-96 md:h-96 lg:w-full lg:h-full max-w-3xl max-h-3xl bg-gradient-to-r from-blue-200 to-purple-200 rounded-full blur-3xl animate-pulse" />
          </div>

          {/* Animated grid background */}
          <div
            className="absolute inset-0"
            style={{
              backgroundImage:
                "radial-gradient(circle at 1px 1px, #f0f0f0 1px, transparent 0)",
              backgroundSize: "40px 40px",
            }}
          ></div>

          {/* Animated floating elements */}
          <div className="absolute inset-0 overflow-hidden opacity-10 pointer-events-none">
            {/* Code fragments */}
            <div className="absolute top-[15%] left-[10%] text-xs md:text-sm font-mono text-blue-600 animate-float-slow">
              {`async function automate() {`}
            </div>
            <div className="absolute top-[20%] left-[12%] text-xs md:text-sm font-mono text-blue-800 animate-float-slower">
              {`  await ai.process(data);`}
            </div>
            <div className="absolute top-[25%] left-[14%] text-xs md:text-sm font-mono text-blue-600 animate-float-slow">
              {`  return optimized;`}
            </div>
            <div className="absolute top-[30%] left-[12%] text-xs md:text-sm font-mono text-blue-800 animate-float-slower">
              {`}`}
            </div>

            {/* Workflow arrows */}
            <div className="absolute top-[60%] right-[15%] rotate-45 animate-pulse-slow">
              <svg
                width="80"
                height="80"
                viewBox="0 0 24 24"
                className="text-blue-200 opacity-50"
              >
                <path
                  d="M7 7h10v10"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                />
                <path
                  d="M7 17L17 7"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                />
              </svg>
            </div>
            <div className="absolute top-[40%] right-[25%] -rotate-12 animate-pulse-slower">
              <svg
                width="60"
                height="60"
                viewBox="0 0 24 24"
                className="text-blue-200 opacity-40"
              >
                <path
                  d="M7 7h10v10"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                />
                <path
                  d="M7 17L17 7"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                />
              </svg>
            </div>

            {/* Connection dots */}
            <div className="absolute h-40 w-40 top-[20%] right-[20%]">
              <div className="relative h-full w-full">
                <div className="absolute h-1 w-1 rounded-full bg-blue-400 animate-ping-slow"></div>
                <div className="absolute h-1 w-1 rounded-full bg-blue-400 top-[30%] left-[80%] animate-ping-slower"></div>
                <div className="absolute h-1 w-1 rounded-full bg-blue-400 top-[70%] left-[40%] animate-ping-slow"></div>
                <div className="absolute h-1 w-1 rounded-full bg-blue-400 top-[90%] left-[90%] animate-ping-slower"></div>
                <svg
                  width="100%"
                  height="100%"
                  className="absolute inset-0 text-blue-200 opacity-30"
                >
                  <line
                    x1="0%"
                    y1="0%"
                    x2="80%"
                    y2="30%"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="0%"
                    y1="0%"
                    x2="40%"
                    y2="70%"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="0%"
                    y1="0%"
                    x2="90%"
                    y2="90%"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="80%"
                    y1="30%"
                    x2="40%"
                    y2="70%"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="80%"
                    y1="30%"
                    x2="90%"
                    y2="90%"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                  <line
                    x1="40%"
                    y1="70%"
                    x2="90%"
                    y2="90%"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  />
                </svg>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="relative z-10 container mx-auto px-4">
            <div className="max-w-5xl mx-auto text-center">
              <div className="relative inline-block">
                <h1 className="font-mono text-4xl md:text-6xl font-bold text-black mb-4 tracking-tight">
                  AAAGentive
                  <span className="text-blue-600 animate-blink">_</span>
                </h1>
                <div className="absolute -top-8 -right-8 text-blue-100 opacity-30 animate-spin-slow hidden md:block">
                  <svg
                    width="60"
                    height="60"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="0.5"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M12 8v8M8 12h8" />
                  </svg>
                </div>
              </div>

              <p className="font-mono pt-4 text-gray-600 mb-8 relative">
                <span className="relative inline-flex items-center">
                  AI
                  <span className="mx-2 inline-flex h-1.5 w-1.5 rounded-full bg-gray-300"></span>
                </span>
                <span className="relative inline-flex items-center">
                  Automation
                  <span className="mx-2 inline-flex h-1.5 w-1.5 rounded-full bg-gray-300"></span>
                </span>
                <span className="relative inline-flex items-center">
                  Agents
                </span>
              </p>

              <div className="grid pt-12 grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto mb-12">
                <div className="bg-gray-50 p-6 rounded-xl border border-gray-100 flex flex-col items-center text-left transition-all hover:shadow-md group">
                  <div className="relative">
                    <Cpu className="w-8 h-8 text-blue-600 mb-4 relative z-10 group-hover:scale-110 transition-transform" />
                    <div className="absolute -inset-2 bg-blue-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity z-0 animate-pulse"></div>
                  </div>
                  <h3 className="font-mono font-bold text-lg mb-2">
                    AI Agents
                  </h3>
                  <p className="font-mono text-sm text-gray-600">
                    Autonomous digital assistants that make decisions, take
                    actions, and collaborate with your team to optimize property
                    operations and guest experiences
                  </p>
                  <div className="mt-4 w-full border-t border-gray-100 pt-4 opacity-0 group-hover:opacity-100 transition-opacity">
                    <div className="flex justify-between items-center text-xs text-gray-400">
                      <div className="flex items-center gap-1">
                        <Code className="w-3 h-3" />
                        <span>Conversational</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Zap className="w-3 h-3" />
                        <span>Autonomous</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 p-6 rounded-xl border border-gray-100 flex flex-col items-center text-left transition-all hover:shadow-md group">
                  <div className="relative">
                    <Workflow className="w-8 h-8 text-blue-600 mb-4 relative z-10 group-hover:scale-110 transition-transform" />
                    <div className="absolute -inset-2 bg-blue-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity z-0 animate-pulse"></div>
                  </div>
                  <h3 className="font-mono font-bold text-lg mb-2">
                    Business Automation
                  </h3>
                  <p className="font-mono text-sm text-gray-600">
                    Streamlined workflows and optimized operations for real
                    estate and hospitality enterprises, enhancing guest
                    experiences and property management efficiency
                  </p>
                  <div className="mt-4 w-full border-t border-gray-100 pt-4 opacity-0 group-hover:opacity-100 transition-opacity">
                    <div className="flex justify-between items-center text-xs text-gray-400">
                      <div className="flex items-center gap-1">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                        </svg>
                        <span>Efficient</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <polyline points="12 6 12 12 16 14"></polyline>
                        </svg>
                        <span>Time-saving</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-6 justify-center mt-2">
                <div className="text-center">
                  <span className="inline-block font-mono text-xs text-gray-500 mb-2">
                    * Currently in research phase
                  </span>
                </div>

                <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
                  <a
                    href="/insights-form"
                    className="group relative overflow-hidden"
                  >
                    <div className="bg-black hover:bg-gray-800 text-white px-6 py-3 rounded-lg font-mono transition-colors flex items-center justify-center gap-2">
                      <span className="absolute inset-0 w-0 bg-blue-600 transition-all duration-500 ease-out group-hover:w-full"></span>
                      <svg
                        className="w-4 h-4 relative z-10"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 4H4V20H11"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 16L20 12L16 8"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M20 12H8"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="relative z-10">Shape Our Solutions</span>
                      <div className="absolute right-2 opacity-0 group-hover:opacity-100 transition-opacity relative z-10">
                        <svg
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 12H19M19 12L12 5M19 12L12 19"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {/* Ping effect behind button */}
                    <span className="absolute -inset-0.5 rounded-lg bg-gradient-to-r from-blue-500 to-indigo-500 opacity-0 group-hover:opacity-30 group-hover:blur-sm transition-all duration-500 -z-10"></span>
                  </a>

                  <div className="hidden md:flex items-center text-gray-400 text-xs">
                    <span className="mx-3">or</span>
                  </div>

                 
                  <div className="flex items-center pl-1 lg:pl-4 ml-1 lg:ml-3 border-l border-gray-100">
                    <button
                      onClick={() => setShowWaitlistModal(true)}
                      className="px-5 py-4 bg-blue-600 hover:bg-black text-white text-sm font-medium rounded-md transition-all duration-300 flex items-center gap-2 relative overflow-hidden group"
                    >
                      <span className="absolute inset-0 w-0 bg-black transition-all duration-500 ease-out group-hover:w-full"></span>
                      <Bot className="w-4 h-4 relative z-10" />
                      <span className="relative z-10">Join Waitlist</span>
                    </button>
                  </div>
                </div>

                <div className="flex items-center justify-center mt-2 opacity-80">
                  <div className="flex items-center space-x-1 text-xs text-gray-500">
                    <svg
                      className="w-4 h-4 text-gray-400"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 12H15"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 9L12 15"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                        stroke="currentColor"
                        strokeWidth="2"
                      />
                    </svg>
                    <span>
                      Your insights directly influence our development roadmap
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WaitlistModal
        isOpen={showWaitlistModal}
        onClose={() => setShowWaitlistModal(false)}
      />
    </>
  );
};

// Add these custom animations to your global CSS or tailwind config

export default HeroSection;
