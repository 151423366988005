import React from "react";
import ReactMarkdown from "react-markdown";

const BlogContent = ({ section }) => {
  return (
    <section key={section.id} className="mb-16">
      <h2 className="text-3xl font-bold mb-6">{section.title}</h2>
      <div className="space-y-6">
        <ReactMarkdown
          className="prose prose-invert prose-lg"
          components={{
            h3: ({ node, ...props }) => (
              <h3 className="text-2xl font-semibold my-4" {...props} />
            ),
            ul: ({ node, ...props }) => (
              <ul className="list-disc pl-6 space-y-2" {...props} />
            ),
            ol: ({ node, ...props }) => (
              <ol className="list-decimal pl-6 space-y-2" {...props} />
            ),
            li: ({ node, ...props }) => (
              <li className="text-gray-300" {...props} />
            ),
            strong: ({ node, ...props }) => (
              <strong className="font-bold text-white" {...props} />
            ),
            p: ({ node, ...props }) => (
              <p className="text-gray-300 leading-relaxed" {...props} />
            ),
          }}
        >
          {section.text_content}
        </ReactMarkdown>

        {section.images?.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
            {section.images.map((image, idx) => (
              <figure key={idx} className="relative">
                <img
                  src={image.url || "/api/placeholder/400/300"}
                  alt={image.alt}
                  className="w-full rounded-lg"
                />
                {image.caption && (
                  <figcaption className="mt-2 text-sm text-gray-400 text-center">
                    {image.caption}
                  </figcaption>
                )}
              </figure>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default BlogContent;
