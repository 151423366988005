// import logo from "./logo.svg";
import { Routes, Navigate, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import Navigation from "./navigation/Navigation";
import Home from "./pages/Home";
import Footer from "./components/footer/Footer";
import BlogPostPage from "./pages/blog/BlogPostPage";
import BlogsPage from "./pages/blog/BlogsPage";
import Workspace from "./pages/workspace/Workspace";
import BusinessInsightsForm from "./pages/BusinessInsightsForm/BusinessInsightsForm";
import ContactPage from "./pages/ContactPage/ContactPage";
import Login from "./pages/Authentication/Login";
import Signup from "./pages/Authentication/Signup";
import AgentsPage from "./pages/AgentsPage/AgentsPage";
import ServicesPage from "./pages/ServicePage/Servicepage";
import AutomationServicesPage from "./pages/ServicePage/AutomationServicesPage";

function App() {
  return (
    <HelmetProvider>
      <div className="min-h-screen bg-zinc-900 ">
        {/* Navigation */}

        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog/:id" element={<BlogPostPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="insights-form" element={<BusinessInsightsForm />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/agents" element={<AgentsPage />} />
          <Route path="services" element={<AutomationServicesPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          {/* <Route
            path="/workspace"
            element={<Navigate to="/workspace/explore" replace />}
          />
          <Route path="/workspace/*" element={<Workspace />} /> */}
        </Routes>
      </div>
    </HelmetProvider>
  );
}

export default App;
