import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronDown,
  HelpCircle,
  MessageSquare,
  Zap,
  Shield,
  Clock,
  Building,
  Hotel,
} from "lucide-react";

const FaqSection = () => {
  const navigate = useNavigate();
  const handleContactSupportClick = () => {
    navigate("/contact"); // Navigate to the /contact path
  };
  const [openIndex, setOpenIndex] = useState(0);

  const faqs = [
    {
      question:
        "How do your AI agents enhance property and hospitality operations?",
      answer:
        "Our AI agents act as intelligent assistants that handle everything from property inquiries to guest services. They autonomously manage bookings, answer questions, schedule viewings, and provide personalized recommendations - all while learning from each interaction to improve service quality. Unlike basic chatbots, our agents can make decisions, take actions, and seamlessly integrate with your existing systems.",
      icon: MessageSquare,
    },
    {
      question: "What specific benefits can I expect for my property business?",
      answer:
        "Property businesses typically see immediate impact in several areas: faster lead response times (often under 1 minute), increased viewing scheduling rates (up to 40% improvement), better client matching with properties, and significant time savings on routine inquiries. Our AI agents work 24/7, ensuring no opportunity is missed while maintaining detailed interaction records for your team.",
      icon: Building,
    },
    {
      question: "How do your solutions improve the guest experience?",
      answer:
        "Our AI agents serve as personal concierges for your guests, available 24/7 to handle bookings, answer questions, make recommendations, and address concerns. They remember guest preferences, anticipate needs, and ensure consistent service quality. Hotels using our solutions typically see higher guest satisfaction scores and increased booking rates through better personalization and response times.",
      icon: Hotel,
    },
    {
      question: "How secure is the data handled by your AI agents?",
      answer:
        "Security is fundamental to our design. All solutions implement end-to-end encryption, role-based access controls, and comply with South African POPIA standards. Your business and client data remains your property, is stored within approved South African territories, and we never train our base models on your proprietary data. We maintain strict data separation between clients.",
      icon: Shield,
    },
    {
      question: "What's involved in implementing an AAAGentive solution?",
      answer:
        "Implementation follows our proven four-step process: (1) Discovery - understanding your specific workflows and requirements; (2) Configuration - customizing the AI agents for your business needs; (3) Training - fine-tuning using your approved content and processes; (4) Deployment - seamless integration with your existing systems. Typical implementation takes 2-4 weeks, with ongoing optimization and support.",
      icon: Clock,
    },
    {
      question: "How do you measure the success of your AI solutions?",
      answer:
        "We establish clear KPIs from the start, measuring metrics like response times, conversion rates, guest satisfaction scores, and time saved. Our dashboard provides real-time insights into agent performance, guest/client satisfaction, and operational efficiency. We conduct regular reviews to ensure continuous improvement and ROI optimization.",
      icon: Zap,
    },
  ];

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg-white mt-8 rounded-2xl p-4 sm:p-6 md:p-8 shadow-sm w-[99%] mx-auto">
      <div className="max-w-3xl mx-auto mb-6 sm:mb-10 text-center">
        <h2 className="font-mono text-xl sm:text-2xl font-bold mb-2 sm:mb-3">
          Frequently Asked Questions
        </h2>
        <p className="text-sm sm:text-base text-gray-600">
          Common questions about our AI agents for real estate and hospitality
        </p>
      </div>

      <div className="max-w-4xl mx-auto">
        <div className="space-y-3 sm:space-y-4">
          {faqs.map((faq, index) => {
            const IconComponent = faq.icon;
            return (
              <div
                key={index}
                className={`border ${
                  openIndex === index
                    ? "border-blue-100 bg-blue-50"
                    : "border-gray-100"
                } rounded-xl overflow-hidden transition-colors duration-200`}
              >
                <button
                  onClick={() => toggleFaq(index)}
                  className="w-full flex items-start justify-between p-3 sm:p-5 text-left"
                >
                  <div className="flex gap-2 sm:gap-4">
                    <div
                      className={`w-8 h-8 sm:w-10 sm:h-10 ${
                        openIndex === index ? "bg-blue-100" : "bg-gray-50"
                      } rounded-md flex items-center justify-center flex-shrink-0 transition-colors`}
                    >
                      <IconComponent
                        className={`w-4 h-4 sm:w-5 sm:h-5 ${
                          openIndex === index
                            ? "text-blue-600"
                            : "text-gray-500"
                        }`}
                      />
                    </div>
                    <span className="font-mono font-medium text-sm sm:text-base pt-1 sm:pt-2 pr-2">
                      {faq.question}
                    </span>
                  </div>
                  <ChevronDown
                    className={`w-4 h-4 sm:w-5 sm:h-5 transform transition-transform duration-200 mt-1 sm:mt-2 flex-shrink-0 ${
                      openIndex === index
                        ? "rotate-180 text-blue-600"
                        : "text-gray-400"
                    }`}
                  />
                </button>

                <div
                  className={`overflow-hidden transition-all duration-200 ${
                    openIndex === index
                      ? "max-h-[400px] sm:max-h-96 opacity-100"
                      : "max-h-0 opacity-0"
                  }`}
                >
                  <div className="p-3 sm:p-5 pt-0 sm:pt-0 pl-12 sm:pl-19 ml-2 sm:ml-14">
                    <p className="text-xs sm:text-sm md:text-base text-gray-600">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-8 sm:mt-10 p-4 sm:p-5 border border-gray-100 rounded-xl bg-gray-50 text-center">
          <div className="flex items-center justify-center gap-1.5 sm:gap-2 mb-2 sm:mb-3">
            <HelpCircle className="w-4 h-4 sm:w-5 sm:h-5 text-blue-600" />
            <p className="font-mono font-medium text-sm sm:text-base">
              Have more questions?
            </p>
          </div>
          <p className="text-xs sm:text-sm text-gray-600 mb-3 sm:mb-4 max-w-lg mx-auto">
            Our team is ready to help you understand how our AI agents can
            transform your property or hospitality business
          </p>
          <button
            onClick={handleContactSupportClick}
            className="bg-white hover:bg-gray-50 text-black border border-gray-200 px-4 sm:px-6 py-2 sm:py-3 rounded-lg font-mono transition-colors text-xs sm:text-sm"
          >
            contact_support()
          </button>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
