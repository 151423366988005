import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Menu,
  X,
  ChevronDown,
  Bot,
  Workflow,
  FileText,
  Headset,
  Zap,
  Building,
  Briefcase,
  HandCoins,
  Users,
  Cpu,
  HelpCircle,
  DollarSign,
} from "lucide-react";
import WaitlistModal from "../components/WaitlistModal/WaitlistModal";

const NavigationLatest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [mobileSections, setMobileSections] = useState({
    solutions: false,
    resources: false,
  });
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  const toggleDropdown = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);
    }
  };

  const toggleMobileSection = (section) => {
    setMobileSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Navigation sections
  const navSections = [
    {
      name: "Agents",
      sectionId: "agents-section",
      icon: Bot,
    },
    {
      name: "Services",
      sectionId: "services-section",
      icon: HandCoins,
    },
    {
      name: "How It Works",
      sectionId: "process-section",
      icon: Workflow,
    },
    {
      name: "About",
      sectionId: "about-section",
      icon: Users,
    },
    {
      name: "Pricing",
      sectionId: "pricing-section",
      icon: DollarSign,
    },
    {
      name: "Contact",
      sectionId: "contact-section",
      icon: Headset,
    },
    {
      name: "FAQ",
      sectionId: "faq-section",
      icon: HelpCircle,
    },
  ];

  // Scroll to section function
  const scrollToSection = (sectionId) => {
    setIsOpen(false); // Close mobile menu if open

    const section = document.getElementById(sectionId);
    if (section) {
      // Calculate position to scroll to (with offset for fixed header)
      const headerOffset = 100; // Adjust based on your header height
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      // Smooth scroll to element
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setActiveSection(sectionId);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset > 20);

      // Determine which section is currently in view
      const sections = navSections.map((item) =>
        document.getElementById(item.sectionId)
      );
      const validSections = sections.filter(Boolean);

      if (validSections.length > 0) {
        const headerOffset = 120; // Slightly larger than scroll offset
        const currentSection = validSections.find((section) => {
          const rect = section.getBoundingClientRect();
          return rect.top <= headerOffset && rect.bottom > headerOffset;
        });

        if (currentSection) {
          setActiveSection(currentSection.id);
        }
      }
    };

    const handleClickOutside = (e) => {
      if (
        !e.target.closest(".dropdown-menu") &&
        !e.target.closest(".dropdown-button")
      ) {
        setActiveDropdown(null);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav
        className={`w-full flex justify-center px-3 py-2 z-40 transition-all duration-300 ${
          isSticky ? "sticky top-0 bg-white/80 backdrop-blur-md" : ""
        }`}
      >
        <div
          className={`w-full max-w-6xl bg-white rounded-xl shadow-sm border border-gray-100 transition-all duration-300 ${
            isSticky ? "py-2" : "py-3"
          }`}
        >
          <div className="mx-auto px-4">
            <div className="flex items-center justify-between h-14">
              {/* Logo */}
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => scrollToSection("hero-section")}
                  className="flex items-center gap-2.5 cursor-pointer"
                >
                  <div className="relative">
                    <img
                      src="/logo-NoB.png"
                      alt="AAAGentive"
                      className="w-8 h-8 sm:w-9 sm:h-9"
                    />
                    <div className="absolute -top-1 -right-1 h-2 w-2 bg-green-400 rounded-full animate-pulse hidden sm:block"></div>
                  </div>
                  <span className="text-lg sm:text-xl text-black font-bold font-mono tracking-tight">
                    AAAGentive<span className="text-blue-600">_</span>
                  </span>
                </button>
              </div>

              {/* Mobile menu button */}
              <div className="flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none transition-colors"
                  aria-expanded={isOpen}
                >
                  <span className="sr-only">Open main menu</span>
                  {isOpen ? <X size={20} /> : <Menu size={20} />}
                </button>
              </div>

              {/* Desktop menu */}
              <div className="hidden md:flex md:items-center md:space-x-1 lg:space-x-4">
                {/* Navigation links */}
                {navSections.map((item) => (
                  <button
                    key={item.sectionId}
                    onClick={() => scrollToSection(item.sectionId)}
                    className={`text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                      activeSection === item.sectionId ? "text-blue-600" : ""
                    }`}
                  >
                    {item.name}
                  </button>
                ))}

                {/* Waitlist button */}
                <div className="flex items-center pl-1 lg:pl-4 ml-1 lg:ml-3 border-l border-gray-100">
                  <button
                    onClick={() => setShowWaitlistModal(true)}
                    className="px-4 py-1.5 bg-black hover:bg-gray-800 text-white text-sm font-medium rounded-md transition-colors flex items-center gap-2"
                  >
                    <Bot className="w-4 h-4" />
                    <span>Join Waitlist</span>
                  </button>
                </div>
              </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
              <div className="md:hidden pt-2 pb-4 border-t border-gray-100 mt-2">
                <div className="space-y-1 px-2">
                  {/* Mobile navigation links */}
                  {navSections.map((item) => (
                    <button
                      key={item.sectionId}
                      onClick={() => scrollToSection(item.sectionId)}
                      className={`flex w-full items-center px-3 py-2 text-base text-gray-700 hover:bg-gray-50 rounded-md ${
                        activeSection === item.sectionId
                          ? "bg-gray-50 text-blue-600"
                          : ""
                      }`}
                    >
                      <item.icon className="w-5 h-5 mr-3 text-gray-400" />
                      {item.name}
                    </button>
                  ))}

                  {/* Mobile waitlist button */}
                  <div className="pt-4 border-t border-gray-100 mt-4">
                    <button
                      onClick={() => {
                        setShowWaitlistModal(true);
                        setIsOpen(false);
                      }}
                      className="w-full px-4 py-2 bg-black hover:bg-gray-800 text-white text-sm font-medium rounded-md transition-colors flex items-center justify-center gap-2"
                    >
                      <Bot className="w-4 h-4" />
                      <span>Join Waitlist</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      <WaitlistModal
        isOpen={showWaitlistModal}
        onClose={() => setShowWaitlistModal(false)}
      />
    </>
  );
};

export default NavigationLatest;
