import React, { useState } from "react";
import {
  Check,
  Bot,
  Workflow,
  Zap,
  Clock,
  Shield,
  Database,
  Headset,
  BarChart4,
  Users,
  Cpu,
  LineChart,
  Flag,
  Settings,
  BookOpen,
  Building,
  Home,
  Hotel,
  Wrench,
  Plus,
  Lock,
  DollarSign,
} from "lucide-react";

const StrategicPricingSection = () => {
  const [billingPeriod, setBillingPeriod] = useState("monthly");
  const [businessSize, setBusinessSize] = useState("small");
  const [selectedAgent, setSelectedAgent] = useState("property-match");

  // AI Agents
  const agents = [
    {
      id: "property-match",
      name: "PropertyMatch AI",
      description:
        "Intelligent leasing assistant for property matching and scheduling",
      icon: Home,
      color: "text-amber-600",
      bgColor: "bg-amber-50",
      borderColor: "border-amber-200",
    },
    {
      id: "maintenance-mate",
      name: "MaintenanceMate",
      description: "Maintenance reporting and issue management AI assistant",
      icon: Wrench,
      color: "text-orange-600",
      bgColor: "bg-orange-50",
      borderColor: "border-orange-200",
    },
    {
      id: "guest-flow",
      name: "GuestFlow AI",
      description:
        "Complete guest journey management for hospitality businesses",
      icon: Hotel,
      color: "text-green-600",
      bgColor: "bg-green-50",
      borderColor: "border-green-200",
    },
  ];

  // Implementation Fee based on business size
  const setupFees = {
    small: {
      base: 2999,
      additionalAgents: 1500,
    },
    medium: {
      base: 4999,
      additionalAgents: 2000,
    },
    large: {
      base: 8000,
      additionalAgents: 3000,
    },
  };

  // Subscription packages - more reasonable pricing
  const subscriptionPackages = [
    {
      name: "Basic Support",
      description: "Essential maintenance for your AI agent",
      monthlyPrices: {
        small: 249,
        medium: 499,
        large: 600,
      },
      yearlyPrices: {
        small: 2490,
        medium: 4990,
        large: 6000,
      },
      features: [
        "Bug fixes and system stability",
        "Basic knowledge base updates",
        "Standard business hours support",
        "Regular LLM model updates",
        "Up to 500 AI interactions/month",
        "Monthly usage reports",
      ],
      icon: Shield,
      accentColor: "border-blue-200 bg-gradient-to-br from-blue-50 to-blue-100",
      buttonClass: "bg-blue-600 hover:bg-blue-700",
    },
    {
      name: "Standard Service",
      description: "Enhanced support with optimization and updates",
      monthlyPrices: {
        small: 899,
        medium: 1299,
        large: 1999,
      },
      yearlyPrices: {
        small: 8990,
        medium: 12990,
        large: 19990,
      },
      features: [
        "Everything in Basic Support",
        "Quarterly knowledge base updates",
        "Monthly performance optimization",
        "Enhanced analytics dashboard",
        "Priority email & phone support",
        "Up to 2,000 AI interactions/month",
        "Minor feature adjustments",
      ],
      icon: Zap,
      accentColor:
        "border-indigo-200 bg-gradient-to-br from-indigo-50 to-indigo-100",
      buttonClass: "bg-indigo-600 hover:bg-indigo-700",
      popular: true,
    },
    {
      name: "Premium Service",
      description: "Comprehensive support with custom development",
      monthlyPrices: {
        small: 1499,
        medium: 2499,
        large: 3999,
      },
      yearlyPrices: {
        small: 14990,
        medium: 24990,
        large: 39990,
      },
      features: [
        "Everything in Standard Service",
        "Custom feature requests (1-2 per quarter)",
        "Bi-weekly strategy sessions",
        "Dedicated account manager",
        "24/7 priority support",
        "Unlimited AI interactions",
        "Custom analytics dashboard",
      ],
      icon: LineChart,
      accentColor:
        "border-purple-200 bg-gradient-to-br from-purple-50 to-purple-100",
      buttonClass: "bg-purple-600 hover:bg-purple-700",
    },
  ];

  // Business size options
  const businessSizes = [
    {
      id: "small",
      name: "Small Business",
      description: "Up to 10 properties or 50 rooms",
      icon: Building,
    },
    {
      id: "medium",
      name: "Medium Business",
      description: "Up to 50 properties or 200 rooms",
      icon: Building,
    },
    {
      id: "large",
      name: "Enterprise",
      description: "Unlimited properties and rooms",
      icon: LineChart,
    },
  ];

  // Calculate total implementation cost
  const calculateImplementationCost = (additionalAgents = 0) => {
    return (
      setupFees[businessSize].base +
      additionalAgents * setupFees[businessSize].additionalAgents
    );
  };

  return (
    <div className="w-[98%] mx-auto py-16 px-4 bg-gradient-to-b from-white to-gray-50 rounded-xl mt-16">
      <div className="max-w-6xl mx-auto">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto mb-12 text-center">
          <h2 className="font-mono text-3xl font-bold mb-4">
            Transparent AI Solution Pricing
          </h2>
          <p className="text-gray-600 mb-8">
            Choose your ideal AI agent, implementation package, and support plan
            for ongoing optimization
          </p>

          {/* Step 1: Select Your AI Agent */}
          <div className="mb-12">
            <div className="inline-flex items-center px-4 py-1.5 bg-black text-white rounded-full text-sm font-mono mb-4">
              <span className="w-5 h-5 rounded-full bg-white text-black flex items-center justify-center mr-2 font-bold">
                1
              </span>
              <span>Select Your AI Agent</span>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
              {agents.map((agent) => (
                <button
                  key={agent.id}
                  onClick={() => setSelectedAgent(agent.id)}
                  className={`relative p-5 rounded-xl transition-all ${
                    selectedAgent === agent.id
                      ? `${agent.borderColor} border-2 ${agent.bgColor} shadow-md`
                      : "bg-white border border-gray-200 hover:border-gray-300"
                  }`}
                >
                  {selectedAgent === agent.id && (
                    <div className="absolute top-3 right-3 w-5 h-5 bg-green-100 rounded-full flex items-center justify-center">
                      <Check className="w-3 h-3 text-green-600" />
                    </div>
                  )}

                  <div
                    className={`w-12 h-12 rounded-xl ${agent.bgColor} flex items-center justify-center mb-3`}
                  >
                    <agent.icon className={`w-6 h-6 ${agent.color}`} />
                  </div>

                  <h3 className="font-mono text-lg font-bold mb-1 text-left">
                    {agent.name}
                  </h3>
                  <p className="text-sm text-gray-600 text-left">
                    {agent.description}
                  </p>
                </button>
              ))}
            </div>

            <div className="mt-4 text-center">
              <span className="inline-flex items-center text-xs text-gray-500 bg-gray-50 px-3 py-1 rounded-full">
                <Plus className="w-3 h-3 mr-1" />
                <span>Additional agents can be added at any time</span>
              </span>
            </div>
          </div>

          {/* Step 2: Select Business Size */}
          <div className="mb-12">
            <div className="inline-flex items-center px-4 py-1.5 bg-black text-white rounded-full text-sm font-mono mb-4">
              <span className="w-5 h-5 rounded-full bg-white text-black flex items-center justify-center mr-2 font-bold">
                2
              </span>
              <span>Select Your Business Size</span>
            </div>

            <div className="flex flex-wrap justify-center gap-4 mt-6">
              {businessSizes.map((size) => (
                <button
                  key={size.id}
                  onClick={() => setBusinessSize(size.id)}
                  className={`flex flex-col items-center px-6 py-4 rounded-xl transition-all ${
                    businessSize === size.id
                      ? "bg-blue-50 border-2 border-blue-200"
                      : "bg-white border border-gray-200 hover:border-blue-100 hover:bg-blue-50/30"
                  }`}
                >
                  <div
                    className={`w-12 h-12 mb-2 rounded-full flex items-center justify-center ${
                      businessSize === size.id ? "bg-blue-100" : "bg-gray-50"
                    }`}
                  >
                    <size.icon
                      className={`w-6 h-6 ${
                        businessSize === size.id
                          ? "text-blue-600"
                          : "text-gray-500"
                      }`}
                    />
                  </div>
                  <div className="font-medium">{size.name}</div>
                  <div className="text-xs text-gray-500 mt-1">
                    {size.description}
                  </div>
                </button>
              ))}
            </div>
          </div>

          {/* Step 3: Implementation Package */}
          <div className="mb-12">
            <div className="inline-flex items-center px-4 py-1.5 bg-black text-white rounded-full text-sm font-mono mb-4">
              <span className="w-5 h-5 rounded-full bg-white text-black flex items-center justify-center mr-2 font-bold">
                3
              </span>
              <span>Implementation Package</span>
            </div>

            <div className="max-w-2xl mx-auto mt-6 bg-white rounded-xl border border-blue-200 overflow-hidden shadow-sm">
              <div className="bg-gradient-to-r from-blue-50 to-indigo-50 px-6 py-4 border-b border-blue-100">
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="font-mono text-xl font-bold mb-1">
                      One-Time Setup
                    </h3>
                    <p className="text-sm text-gray-600">
                      Complete implementation of your selected AI agent
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    {(() => {
                      const agent = agents.find((a) => a.id === selectedAgent);
                      const AgentIcon = agent.icon;
                      return <AgentIcon className={`w-6 h-6 ${agent.color}`} />;
                    })()}
                    <Settings className="w-6 h-6 text-gray-400" />
                  </div>
                </div>
              </div>

              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <span className="text-3xl font-bold">
                      R {calculateImplementationCost().toLocaleString()}
                    </span>
                    <span className="text-gray-500 ml-2">one-time fee</span>
                  </div>

                  <div className="flex items-center gap-2">
                    <button className="text-sm px-3 py-1 border border-gray-200 rounded-md hover:bg-gray-50 transition-colors flex items-center gap-1">
                      <Plus className="w-3.5 h-3.5" />
                      <span>Add Agent</span>
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="flex items-start gap-2">
                    <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <p className="font-medium">Custom AI Agent Setup</p>
                      <p className="text-xs text-gray-500">
                        Tailored to your business needs
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <p className="font-medium">Knowledge Base Creation</p>
                      <p className="text-xs text-gray-500">
                        Built from your business data
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <p className="font-medium">System Integrations</p>
                      <p className="text-xs text-gray-500">
                        Connect with your existing tools
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start gap-2">
                    <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                    <div>
                      <p className="font-medium">Team Training</p>
                      <p className="text-xs text-gray-500">
                        Comprehensive onboarding
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <div className="inline-flex items-center px-3 py-1.5 bg-amber-50 text-amber-800 rounded-full text-xs">
                    <DollarSign className="w-3.5 h-3.5 mr-1" />
                    <span>
                      Additional agents: R{" "}
                      {setupFees[
                        businessSize
                      ].additionalAgents.toLocaleString()}{" "}
                      each
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Step 4: Choose Support Plan */}
          <div>
            <div className="inline-flex items-center px-4 py-1.5 bg-black text-white rounded-full text-sm font-mono mb-4">
              <span className="w-5 h-5 rounded-full bg-white text-black flex items-center justify-center mr-2 font-bold">
                4
              </span>
              <span>Choose Support Plan</span>
            </div>

            {/* Billing Toggle */}
            <div className="inline-flex items-center p-1 bg-gray-100 rounded-lg mt-6 mb-8">
              <button
                onClick={() => setBillingPeriod("monthly")}
                className={`px-4 py-2 text-sm font-medium rounded-md transition-colors ${
                  billingPeriod === "monthly"
                    ? "bg-white shadow-sm text-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setBillingPeriod("yearly")}
                className={`px-4 py-2 text-sm font-medium rounded-md transition-colors flex items-center ${
                  billingPeriod === "yearly"
                    ? "bg-white shadow-sm text-blue-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                Yearly
                <span className="ml-1 px-1.5 py-0.5 text-xs bg-green-100 text-green-800 rounded-full">
                  Save 10%
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* Subscription Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8">
          {subscriptionPackages.map((pkg) => (
            <div
              key={pkg.name}
              className={`relative bg-white rounded-2xl border ${
                pkg.popular
                  ? "border-indigo-200 shadow-lg"
                  : "border-gray-200 shadow-sm"
              } overflow-hidden transition-all hover:shadow-md`}
            >
              {pkg.popular && (
                <div className="absolute top-0 right-0 bg-indigo-600 text-white text-xs font-bold px-3 py-1 uppercase tracking-wide">
                  Most Popular
                </div>
              )}

              <div
                className={`p-6 ${pkg.accentColor} border-b border-gray-100`}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-mono text-2xl font-bold mb-1">
                      {pkg.name}
                    </h3>
                    <p className="text-sm text-gray-600 mb-4">
                      {pkg.description}
                    </p>
                  </div>

                  <div className="w-10 h-10 flex items-center justify-center rounded-lg bg-white/50">
                    <pkg.icon className="w-6 h-6 text-gray-700" />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="flex items-baseline">
                    <span className="text-3xl font-bold">
                      R{" "}
                      {billingPeriod === "monthly"
                        ? pkg.monthlyPrices[businessSize].toLocaleString()
                        : pkg.yearlyPrices[businessSize].toLocaleString()}
                    </span>
                    <span className="text-gray-600 ml-1">
                      /{billingPeriod === "monthly" ? "month" : "year"}
                    </span>
                  </div>
                  <p className="text-xs text-gray-500 mt-1">Per AI agent</p>
                </div>
              </div>

              <div className="p-6">
                <div className="flex items-center gap-2 mb-4">
                  <div className="w-8 h-8 rounded-full bg-gray-50 flex items-center justify-center">
                    {(() => {
                      const agent = agents.find((a) => a.id === selectedAgent);
                      const AgentIcon = agent.icon;
                      return <AgentIcon className={`w-4 h-4 ${agent.color}`} />;
                    })()}
                  </div>
                  <span className="text-sm font-medium">
                    {agents.find((a) => a.id === selectedAgent).name}
                  </span>
                </div>

                <ul className="space-y-3 mb-6">
                  {pkg.features.map((feature, index) => (
                    <li key={index} className="flex items-start gap-2">
                      <Check className="w-5 h-5 text-green-500 flex-shrink-0 mt-0.5" />
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>

                <button
                  className={`w-full py-3 ${pkg.buttonClass} text-white rounded-lg font-medium transition-colors mt-4`}
                >
                  Choose Plan
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Custom Solution */}
        {/* <div className="mt-12 bg-gray-50 border border-gray-200 rounded-xl p-6 text-center max-w-3xl mx-auto">
          <h3 className="font-mono text-xl font-bold mb-2">
            Need a Custom Solution?
          </h3>
          <p className="text-gray-600 text-sm mb-4">
            We can build a multi-agent solution tailored to your specific
            business requirements
          </p>
          <button className="inline-flex items-center gap-2 px-5 py-2.5 bg-black text-white rounded-lg font-medium hover:bg-gray-800 transition-colors">
            <BookOpen className="w-4 h-4" />
            <span>Schedule a Consultation</span>
          </button>
        </div> */}

        {/* FAQ */}
        <div className="mt-16 max-w-3xl mx-auto">
          <h3 className="font-mono text-xl font-bold mb-6 text-center">
            Common Questions
          </h3>

          <div className="space-y-4">
            <div className="bg-white rounded-lg border border-gray-200 p-5">
              <h4 className="font-semibold text-lg mb-2">
                How many AI agents can I implement?
              </h4>
              <p className="text-gray-600 text-sm">
                You can start with any one AI agent that best suits your needs,
                and add more at any time. Each additional agent requires its own
                implementation fee and support subscription. Many clients start
                with one agent to see the ROI before expanding.
              </p>
            </div>

            <div className="bg-white rounded-lg border border-gray-200 p-5">
              <h4 className="font-semibold text-lg mb-2">
                Can I change my support plan later?
              </h4>
              <p className="text-gray-600 text-sm">
                Yes, you can upgrade or downgrade your support plan at any time.
                Upgrades take effect immediately, while downgrades will take
                effect at the start of your next billing cycle. There are no
                penalties for changing plans.
              </p>
            </div>

            <div className="bg-white rounded-lg border border-gray-200 p-5">
              <h4 className="font-semibold text-lg mb-2">
                What happens if I need multiple AI agents?
              </h4>
              <p className="text-gray-600 text-sm">
                Many businesses benefit from implementing multiple AI agents
                that work together. For example, a property management company
                might use PropertyMatch AI for leasing and MaintenanceMate for
                handling repair requests. Each agent is priced separately, but
                we offer discounted implementation fees for additional agents.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategicPricingSection;
