import React, { useState } from "react";
import {
  Bot,
  Mail,
  MapPin,
  MessageSquare,
  Phone,
  Send,
  Clock,
  Terminal,
  Zap,
  ChevronRight,
  Workflow,
  Code,
} from "lucide-react";
import Navigation from "../../navigation/Navigation";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    company: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Simulate form submission
    setTimeout(() => {
      setSubmitted(true);
      setLoading(false);
    }, 1500);
  };

  return (
    <>
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <Navigation />

        <div className="relative z-10 max-w-7xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="text-center mb-16">
            <div className="inline-block">
              <div className="flex items-center justify-center">
                <div className="relative">
                  <div className="w-12 h-12 bg-blue-500 opacity-20 rounded-full absolute -top-1 -left-1 animate-pulse"></div>
                  <img
                    src="/logo-NoB.png"
                    alt="AAAGentive"
                    className="w-12 h-12 relative z-10"
                  />
                </div>
                <h1 className="ml-3 text-3xl font-bold font-mono tracking-tight text-gray-900">
                  AAAGentive
                  <span className="text-blue-600 animate-blink">_</span>
                </h1>
              </div>
            </div>

            <h2 className="mt-8 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              <span className="block">Connect with our team</span>
            </h2>
            <p className="mt-4 max-w-md mx-auto text-base text-gray-500">
              Have questions about our AI & automation solutions? Our team is
              ready to assist.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Contact Form Section */}
            <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
              <div className="relative">
                {/* Decorative terminal header */}
                <div className="bg-black px-4 py-2 flex items-center">
                  <div className="flex space-x-2">
                    <div className="w-3 h-3 rounded-full bg-red-500"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                    <div className="w-3 h-3 rounded-full bg-green-500"></div>
                  </div>
                  <div className="ml-4 text-xs text-gray-400 font-mono">
                    contact@aaagentive ~ message.js
                  </div>
                </div>

                {!submitted ? (
                  <form onSubmit={handleSubmit} className="p-6 sm:p-8">
                    <div className="mb-6">
                      <div className="mb-2 flex items-center">
                        <Terminal className="w-4 h-4 text-blue-500 mr-2" />
                        <span className="text-sm font-medium text-gray-700 font-mono">
                          message.send()
                        </span>
                      </div>
                      <div className="border-b border-gray-200 w-full mb-6"></div>

                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Name
                          </label>
                          <div className="relative">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors pl-8"
                              required
                            />
                            <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400">
                              <span className="font-mono text-xs">$</span>
                            </div>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Email
                          </label>
                          <div className="relative">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors pl-8"
                              required
                            />
                            <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400">
                              <span className="font-mono text-xs">@</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-6">
                        <label
                          htmlFor="company"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Company
                        </label>
                        <div className="relative">
                          <input
                            type="text"
                            id="company"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors pl-8"
                          />
                          <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2 text-gray-400">
                            <span className="font-mono text-xs">&gt;</span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-6">
                        <label
                          htmlFor="subject"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Subject
                        </label>
                        <select
                          id="subject"
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors"
                          required
                        >
                          <option value="" disabled>
                            Select a subject
                          </option>
                          <option value="general">General Inquiry</option>
                          <option value="demo">Request a Demo</option>
                          <option value="pricing">Pricing Information</option>
                          <option value="support">Technical Support</option>
                          <option value="partnership">
                            Partnership Opportunity
                          </option>
                        </select>
                      </div>

                      <div className="mb-6">
                        <label
                          htmlFor="message"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Message
                        </label>
                        <div className="relative">
                          <div className="absolute top-3 left-3 flex flex-col items-center">
                            <div className="w-px h-full bg-gray-200"></div>
                          </div>
                          <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows={6}
                            className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors pl-8"
                            placeholder="Tell us how we can help..."
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className={`inline-flex items-center px-5 py-2.5 border border-transparent rounded-lg shadow-sm text-white bg-black hover:bg-gray-800 focus:outline-none font-mono text-sm transition-all relative overflow-hidden ${
                          loading ? "cursor-not-allowed" : ""
                        }`}
                        disabled={loading}
                      >
                        {loading ? (
                          <>
                            <span className="mr-2 inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
                            Sending...
                          </>
                        ) : (
                          <>
                            <span className="relative z-10">
                              submit_message()
                            </span>
                            <div className="absolute inset-0 h-full w-0 bg-blue-600 transition-all duration-300 group-hover:w-full"></div>
                            <Send className="w-4 h-4 ml-2 relative z-10" />
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="p-8 text-center">
                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-100 mb-6">
                      <Zap className="h-8 w-8 text-green-600" />
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">
                      Message Sent Successfully
                    </h3>
                    <p className="text-gray-600 mb-6">
                      Thank you for reaching out. Our team will respond to your
                      inquiry within 24 hours.
                    </p>
                    <div className="inline-flex justify-center items-center">
                      <span className="inline-flex h-2 w-2 rounded-full bg-green-500 mr-2 animate-pulse"></span>
                      <span className="text-sm text-gray-500 font-mono">
                        Message delivered to support queue
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Contact Information */}
            <div className="flex flex-col gap-8">
              {/* Contact Methods */}
              <div className="bg-white rounded-2xl p-6 sm:p-8 shadow-sm border border-gray-100">
                <h3 className="font-mono text-lg font-semibold text-gray-900 mb-6 flex items-center">
                  <Code className="w-5 h-5 text-blue-500 mr-2" />
                  contact.methods
                </h3>

                <div className="space-y-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-50 text-blue-600">
                        <Mail className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h4 className="text-base font-medium text-gray-900">
                        Email
                      </h4>
                      <p className="mt-1 text-sm text-gray-500">
                        Our team typically responds within 24 hours
                      </p>
                      <a
                        href="mailto:contact@aaagentive.com"
                        className="mt-2 inline-block text-sm font-medium text-blue-600 hover:text-blue-500 font-mono"
                      >
                        contact@aaagentive.com
                      </a>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-50 text-blue-600">
                        <Phone className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h4 className="text-base font-medium text-gray-900">
                        Phone
                      </h4>
                      <p className="mt-1 text-sm text-gray-500">
                        Monday-Friday from 9am to 5pm SAST
                      </p>
                      <a
                        href="tel:+27870000000"
                        className="mt-2 inline-block text-sm font-medium text-blue-600 hover:text-blue-500 font-mono"
                      >
                        +27 87 000 0000
                      </a>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-50 text-blue-600">
                        <MessageSquare className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h4 className="text-base font-medium text-gray-900">
                        Live Chat
                      </h4>
                      <p className="mt-1 text-sm text-gray-500">
                        Available on weekdays during business hours
                      </p>
                      <button className="mt-2 inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-500 font-mono">
                        Start conversation
                        <ChevronRight className="ml-1 h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* FAQ & Location */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
                  <h3 className="font-mono text-md font-semibold text-gray-900 mb-4">
                    Quick Links
                  </h3>
                  <ul className="space-y-3">
                    <li>
                      <a
                        href="/faq"
                        className="group inline-flex items-center text-gray-500 hover:text-blue-600 transition-colors"
                      >
                        <div className="mr-3 h-8 w-8 rounded-md bg-gray-50 flex items-center justify-center group-hover:bg-blue-50 transition-colors">
                          <Bot className="h-4 w-4 text-gray-400 group-hover:text-blue-500 transition-colors" />
                        </div>
                        <span className="text-sm">
                          Frequently Asked Questions
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/documentation"
                        className="group inline-flex items-center text-gray-500 hover:text-blue-600 transition-colors"
                      >
                        <div className="mr-3 h-8 w-8 rounded-md bg-gray-50 flex items-center justify-center group-hover:bg-blue-50 transition-colors">
                          <Workflow className="h-4 w-4 text-gray-400 group-hover:text-blue-500 transition-colors" />
                        </div>
                        <span className="text-sm">Documentation</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/blog"
                        className="group inline-flex items-center text-gray-500 hover:text-blue-600 transition-colors"
                      >
                        <div className="mr-3 h-8 w-8 rounded-md bg-gray-50 flex items-center justify-center group-hover:bg-blue-50 transition-colors">
                          <Code className="h-4 w-4 text-gray-400 group-hover:text-blue-500 transition-colors" />
                        </div>
                        <span className="text-sm">Blog & Resources</span>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-100">
                  <h3 className="font-mono text-md font-semibold text-gray-900 mb-4">
                    Office Location
                  </h3>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-10 w-10 rounded-md bg-gray-50 text-gray-500">
                        <MapPin className="h-5 w-5" />
                      </div>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-gray-500">
                        Cape Town, South Africa
                      </p>
                      <p className="mt-1 text-xs text-gray-400">
                        Remote-first company with local presence
                      </p>
                    </div>
                  </div>

                  <div className="mt-4 pt-4 border-t border-gray-100">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-10 w-10 rounded-md bg-gray-50 text-gray-500">
                          <Clock className="h-5 w-5" />
                        </div>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm text-gray-500">Business Hours</p>
                        <p className="mt-1 text-xs text-gray-400">
                          Monday-Friday: 9am - 5pm SAST
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CSS for background grid pattern */}
        <style jsx>{`
          .bg-grid-pattern {
            background-size: 40px 40px;
            background-image: radial-gradient(
              circle,
              #e0e7ff 1px,
              transparent 1px
            );
            mask-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 1) 0%,
              transparent 100%
            );
          }

          @keyframes blink {
            0%,
            100% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
          }

          .animate-blink {
            animation: blink 1s infinite;
          }
        `}</style>
      </div>
    </>
  );
};

export default ContactPage;
