import React from "react";
import {
  Bot,
  Code,
  Cpu,
  Database,
  Layers,
  Lightbulb,
  Workflow,
  Twitter,
  Linkedin,
  Github,
  Building,
  Hotel,
  Zap,
  Terminal,
  Server,
} from "lucide-react";

const AboutSection = () => {
  const technologies = [
    {
      category: "AI & Automation",
      items: ["LangChain", "LLMs", "RAG", "Vector Databases", "n8n", "Make"],
      icon: <Bot className="w-5 h-5 text-blue-600" />,
    },
    {
      category: "Backend & API",
      items: [
        "Python",
        "FastAPI",
        "Django",
        "Java",
        "Spring Boot",
        "REST API",
        "GraphQL",
      ],
      icon: <Server className="w-5 h-5 text-blue-600" />,
    },
    {
      category: "Database & Storage",
      items: ["PostgreSQL", "MySQL", "MongoDB", "Redis", "Supabase", "AWS RDS"],
      icon: <Database className="w-5 h-5 text-blue-600" />,
    },
    {
      category: "Frontend & Mobile",
      items: ["React Js", "React Native", "Tailwind CSS", "TypeScript"],
      icon: <Layers className="w-5 h-5 text-blue-600" />,
    },
  ];

  return (
    <div className="w-[98%] mx-auto py-16 px-4 bg-gradient-to-b from-white to-gray-50 rounded-xl mt-16">
      <div className="max-w-6xl mx-auto">
        {/* Company Story */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <div className="mb-6">
              <div className="inline-flex items-center bg-blue-50 px-3 py-1 rounded-full text-blue-700 text-sm font-mono mb-4">
                <Lightbulb className="w-4 h-4 mr-2" />
                <span>Founded in 2025</span>
              </div>
              <h2 className="font-mono text-3xl font-bold mb-4">Our Story</h2>
              <p className="text-gray-700 mb-4">
                AAAGentive is a specialized AI and automation agency creating
                intelligent solutions for real estate and hospitality businesses
                across South Africa. We develop conversational AI agents and
                custom automation workflows that streamline operations, enhance
                customer experiences, and drive efficiency.
              </p>
              <p className="text-gray-700 mb-4">
                Our approach combines the natural feel of human conversation
                with the efficiency of intelligent automation. As a remote-first
                team based in Pretoria, we're committed to advancing AI adoption
                among South African businesses.
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="border border-gray-100 rounded-lg p-4 bg-white">
                <div className="flex items-center gap-3 mb-3">
                  <div className="w-10 h-10 rounded-full bg-blue-50 flex items-center justify-center">
                    <Building className="w-5 h-5 text-blue-600" />
                  </div>
                  <h3 className="font-bold">Real Estate Focus</h3>
                </div>
                <p className="text-sm text-gray-600">
                  AI-powered solutions for property management, leasing, and
                  customer engagement
                </p>
              </div>

              <div className="border border-gray-100 rounded-lg p-4 bg-white">
                <div className="flex items-center gap-3 mb-3">
                  <div className="w-10 h-10 rounded-full bg-blue-50 flex items-center justify-center">
                    <Hotel className="w-5 h-5 text-blue-600" />
                  </div>
                  <h3 className="font-bold">Hospitality Solutions</h3>
                </div>
                <p className="text-sm text-gray-600">
                  Guest experience enhancement and operational efficiency for
                  hotels and resorts
                </p>
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="absolute -z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-64 h-64 bg-blue-100 rounded-full opacity-20 blur-2xl"></div>
            <div className="bg-white border border-gray-100 rounded-2xl p-8 shadow-sm">
              <h3 className="font-mono text-xl font-bold mb-4">Our Approach</h3>

              <div className="space-y-5">
                <div className="flex items-start gap-3">
                  <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                    <Bot className="w-4 h-4 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">Conversational AI</h4>
                    <p className="text-sm text-gray-600">
                      Industry-specific AI agents that understand context and
                      communicate naturally
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                    <Workflow className="w-4 h-4 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">Custom Automation</h4>
                    <p className="text-sm text-gray-600">
                      Tailored workflows designed for your unique business
                      processes and needs
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                    <Layers className="w-4 h-4 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">System Integration</h4>
                    <p className="text-sm text-gray-600">
                      Seamless connection with your existing tools, platforms,
                      and processes
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="w-8 h-8 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                    <Zap className="w-4 h-4 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-semibold mb-1">
                      Continuous Improvement
                    </h4>
                    <p className="text-sm text-gray-600">
                      Data-driven optimization ensuring your systems get smarter
                      over time
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /* Founder Profile   */}
        <div className="mb-16">
          <div className="text-center mb-12">
            <h2 className="font-mono text-3xl font-bold mb-2">
              Meet Our Founder
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              The vision and expertise behind AAAGentive
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="col-span-1">
              <div className="bg-white rounded-2xl overflow-hidden shadow-sm border border-gray-100">
                <div className="aspect-[4/3] bg-gradient-to-br from-blue-50 to-indigo-50 flex items-center justify-center">
                  <img
                    src="https://www.tresorkl.com/tresorkl.jpeg"
                    alt="Tresor KL"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="font-mono text-xl font-bold mb-1">
                    Tresor KL
                  </h3>
                  <p className="text-gray-600 text-sm mb-4">
                    Founder & Lead Developer
                  </p>

                  <p className="text-sm text-gray-700 mb-4">
                    Backend-focused developer with a strong Computer Science
                    foundation from Tshwane University of Technology, building
                    robust, scalable systems that solve real business problems.
                  </p>

                  <div className="flex items-center space-x-4 mt-4">
                    <a
                      href="https://www.linkedin.com/in/tresor-kl-4a09161a1/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-blue-600 transition-colors"
                    >
                      <Linkedin className="w-5 h-5" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1 lg:col-span-2">
              <div className="bg-white rounded-2xl p-8 shadow-sm border border-gray-100 h-full">
                <h3 className="font-mono text-xl font-bold mb-4">
                  Technical Expertise
                </h3>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-8">
                  {technologies.map((techGroup, index) => (
                    <div key={index}>
                      <div className="flex items-center gap-2 mb-3">
                        {techGroup.icon}
                        <h4 className="font-semibold">{techGroup.category}</h4>
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {techGroup.items.map((tech, i) => (
                          <span
                            key={i}
                            className="px-3 py-1 bg-gray-50 text-gray-700 rounded-md text-xs font-mono"
                          >
                            {tech}
                          </span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="mt-8 pt-6 border-t border-gray-100">
                  <h4 className="font-semibold mb-3">My Approach</h4>
                  <p className="text-sm text-gray-700 mb-4">
                    I combine technical expertise with business understanding,
                    allowing me to deliver solutions that not only work
                    technically but create measurable business impact. Whether
                    it's building high-performance backends, developing mobile
                    applications, or implementing AI automation, I focus on
                    creating systems that are maintainable, scalable, and solve
                    the core business challenge.
                  </p>

                  <div className="flex items-center gap-2 text-blue-600 font-medium text-sm">
                    <Terminal className="w-4 h-4" />
                    <span>
                      Solving real business problems through technology
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-2xl p-8 text-center">
          <h3 className="font-mono text-2xl font-bold mb-4">Our Vision</h3>
          <p className="text-gray-700 max-w-3xl mx-auto">
            To transform how South African businesses operate through
            intelligent automation, making advanced AI technology accessible and
            impactful for companies of all sizes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
