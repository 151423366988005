import React from "react";
import {
  Search,
  Lightbulb,
  Cog,
  Database,
  BookOpen,
  Users,
  MessageSquare,
  Check,
  Settings,
  ArrowRight,
  Key,
  AlertCircle,
  FileText,
  Bot,
  Lock,
  Zap,
  TrendingUp,
} from "lucide-react";

const ProcessSection = () => {
  const processSteps = [
    {
      id: 1,
      title: "Discovery & Analysis",
      description:
        "We understand your business needs and challenges through in-depth consultation",
      icon: Search,
      details: [
        "Comprehensive business process analysis",
        "Identification of automation opportunities",
        "Definition of key success metrics",
      ],
    },
    {
      id: 2,
      title: "Solution Design",
      description:
        "Our team determines the optimal AI and automation solution for your specific requirements",
      icon: Lightbulb,
      details: [
        "Custom solution architecture",
        "Technology stack selection",
        "Integration roadmap planning",
      ],
    },
    {
      id: 3,
      title: "System Integration",
      description: "Secure connection to your existing tools and platforms",
      icon: Settings,
      details: [
        "Establishment of secure API connections",
        "Implementation of data pipelines",
        "Custom integration development where needed",
      ],
    },
    {
      id: 4,
      title: "Knowledge Building",
      description:
        "We create a comprehensive knowledge base from your business information",
      icon: BookOpen,
      details: [
        "Structured data processing (databases, CRMs)",
        "Unstructured data analysis (documents, communications)",
        "Custom knowledge graph development",
      ],
    },
    {
      id: 5,
      title: "AI Agent Development",
      description:
        "Custom AI agents are built and trained using your knowledge base",
      icon: Bot,
      details: [
        "Conversational flow design",
        "Natural language processing optimization",
        "Decision-making capability implementation",
      ],
    },
    {
      id: 6,
      title: "Deployment & Oversight",
      description:
        "We implement human-in-the-loop systems and launch across your selected interfaces",
      icon: Users,
      details: [
        "Quality assurance testing",
        "Human oversight configuration",
        "Interface deployment (Web, WhatsApp, Voice)",
      ],
    },
  ];

  return (
    <div className="w-[98%] mx-auto py-16 px-4 bg-gradient-to-b from-white to-gray-50 rounded-xl mt-16">
      <div className="max-w-6xl mx-auto">
        {/* // <div className="flex justify-center items-center min-h-screen">
    //   <div className="relative min-h-screen w-[99%] md:w-[99%] lg:w-[99%] bg-white flex items-center justify-center overflow-hidden rounded-2xl"> */}
        {/* Section Header */}
        <div className="max-w-3xl mx-auto mb-16 text-center">
          <h2 className="font-mono text-3xl font-bold mb-4">
            Our AI & Automation Process
          </h2>
          <p className="text-gray-600">
            We transform your business operations through a structured,
            transparent approach to implementing intelligent automation
            solutions.
          </p>
        </div>

        {/* Process Flow Visualization */}
        <div className="relative mb-16">
          <div className="hidden md:block absolute top-1/2 left-0 right-0 h-0.5 bg-gray-100 -translate-y-1/2 z-0"></div>

          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-6">
            {processSteps.map((step) => {
              const StepIcon = step.icon;
              return (
                <div
                  key={step.id}
                  className="relative z-10 flex flex-col items-center"
                >
                  <div className="w-16 h-16 rounded-full bg-white flex items-center justify-center shadow-sm border border-gray-100 mb-4 group-hover:border-blue-200 transition-colors">
                    <div className="w-12 h-12 rounded-full bg-blue-50 flex items-center justify-center">
                      <StepIcon className="w-6 h-6 text-blue-600" />
                    </div>
                  </div>

                  <div className="text-center">
                    <div className="font-mono text-xl font-bold text-gray-700 mb-1">
                      {step.id}
                    </div>
                    <h3 className="font-mono font-bold text-lg mb-2">
                      {step.title}
                    </h3>
                    <p className="text-sm text-gray-600">{step.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Detailed Explanation */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl mx-auto">
          <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-10 h-10 rounded-full bg-blue-50 flex items-center justify-center">
                <Key className="w-5 h-5 text-blue-600" />
              </div>
              <h3 className="font-mono font-bold text-xl">
                System Access & Security
              </h3>
            </div>

            <ul className="space-y-4">
              <li className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <Lock className="w-3 h-3 text-blue-600" />
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Secure Access Protocol
                  </h4>
                  <p className="text-sm text-gray-600">
                    We establish secure connections to your business tools and
                    applications through official APIs and authentication
                    methods. All access is thoroughly documented and secured
                    with enterprise-grade encryption.
                  </p>
                </div>
              </li>

              <li className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <FileText className="w-3 h-3 text-blue-600" />
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Clear Documentation & NDAs
                  </h4>
                  <p className="text-sm text-gray-600">
                    We provide comprehensive documentation specifying exactly
                    what systems we'll access, what actions our automation will
                    take, and what data will be processed. This is backed by
                    strong NDAs to protect your business information.
                  </p>
                </div>
              </li>

              <li className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <AlertCircle className="w-3 h-3 text-blue-600" />
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Transparent Boundaries
                  </h4>
                  <p className="text-sm text-gray-600">
                    We clearly define what our systems will and won't do,
                    establishing proper authorization levels and access
                    limitations to ensure our automation operates only within
                    approved parameters.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm border border-gray-100">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-10 h-10 rounded-full bg-blue-50 flex items-center justify-center">
                <Database className="w-5 h-5 text-blue-600" />
              </div>
              <h3 className="font-mono font-bold text-xl">
                Knowledge Base Development
              </h3>
            </div>

            <ul className="space-y-4">
              <li className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <Zap className="w-3 h-3 text-blue-600" />
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Comprehensive Data Processing
                  </h4>
                  <p className="text-sm text-gray-600">
                    We transform your business information from various sources
                    (documents, databases, CRMs) into structured knowledge that
                    powers intelligent agent responses and decision-making
                    capabilities.
                  </p>
                </div>
              </li>

              <li className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <TrendingUp className="w-3 h-3 text-blue-600" />
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Continuous Improvement
                  </h4>
                  <p className="text-sm text-gray-600">
                    The knowledge base is continuously updated and refined based
                    on new interactions, ensuring the AI agents become
                    increasingly effective over time at handling complex
                    scenarios.
                  </p>
                </div>
              </li>

              <li className="flex items-start gap-3">
                <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center flex-shrink-0 mt-0.5">
                  <MessageSquare className="w-3 h-3 text-blue-600" />
                </div>
                <div>
                  <h4 className="font-semibold text-gray-900 mb-1">
                    Human Oversight Integration
                  </h4>
                  <p className="text-sm text-gray-600">
                    We implement human-in-the-loop systems that allow for expert
                    intervention when needed, ensuring quality control while
                    capturing new knowledge from human decisions.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Technology Stack */}
        <div className="mt-16 max-w-3xl mx-auto text-center">
          <h3 className="font-mono text-lg font-bold mb-2">
            Our Technology Stack
          </h3>
          <p className="text-sm text-gray-600 mb-6">
            We combine powerful development tools with low-code solutions to
            create flexible, scalable automation systems
          </p>

          {/* <div className="inline-flex flex-wrap justify-center items-center gap-3 bg-white py-4 px-6 rounded-xl border border-gray-100">
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              Python
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              React
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              n8n
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              Make.com
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              Vapi
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              LLM Models
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              WhatsApp API
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              REST APIs
            </div>
            <div className="px-3 py-1.5 bg-blue-50 rounded-md text-blue-700 text-xs font-mono">
              And more...
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProcessSection;
