import React, { useState } from "react";
import {
  Home,
  Wrench,
  Hotel,
  ArrowRight,
  Calendar,
  MessageSquare,
  CheckCircle2,
  Search,
  Clock,
  Image,
  Bell,
  Tag,
  Star,
  MessagesSquare,
} from "lucide-react";

// Agent data with more detailed information
const agentData = [
  {
    id: "property-match",
    name: "PropertyMatch AI",
    tagline: "Your 24/7 AI leasing assistant",
    description:
      "An intelligent conversational agent that handles the entire leasing process, from answering property questions to scheduling viewings and matching tenants with their ideal properties.",
    icon: Home,
    iconBg: "bg-amber-50",
    iconColor: "text-amber-600",
    tags: ["Real Estate", "Lead Generation", "Tenant Matching"],
    platforms: ["WhatsApp", "Web Interface", "Voice (Coming Soon)"],
    features: [
      {
        title: "Property Matching & Recommendations",
        description:
          "Analyzes tenant preferences and matches them with available properties using natural language processing",
        icon: Search,
        steps: [
          "Answer common FAQs about properties (pricing, policies, availability)",
          "Analyze tenant requirements (budget, location, amenities)",
          "Send personalized property suggestions with relevant summaries",
          "Flag mismatches and suggest suitable alternatives",
        ],
      },
      {
        title: "Scheduling & Coordination",
        description:
          "Automates the viewing and appointment process, saving time for agents and tenants",
        icon: Calendar,
        steps: [
          "Schedule property viewings (syncs with Calendar/Calendly)",
          "Send automated reminders to all parties",
          "Handle rescheduling and cancellation requests",
          "Coordinate between tenants and human agents",
        ],
      },
    ],
  },
  {
    id: "maintenance-mate",
    name: "MaintenanceMate AI",
    tagline: "24/7 property maintenance assistant",
    description:
      "A responsive WhatsApp agent that helps tenants report maintenance issues with images and text, providing troubleshooting guidance and alerting property managers when necessary.",
    icon: Wrench,
    iconBg: "bg-orange-50",
    iconColor: "text-orange-600",
    tags: ["Maintenance", "Issue Reporting", "Tenant Support"],
    platforms: ["WhatsApp"],
    features: [
      {
        title: "Issue Identification & Reporting",
        description:
          "Quickly identifies properties and maintenance issues from tenant reports",
        icon: Bell,
        steps: [
          "Verify tenant identity and property information",
          "Process maintenance reports with text descriptions",
          "Analyze uploaded images to assess damage severity",
          "Categorize issues by urgency and type",
        ],
      },
      {
        title: "Troubleshooting & Resolution",
        description:
          "Provides immediate guidance and ensures proper resolution of maintenance issues",
        icon: CheckCircle2,
        steps: [
          "Offer step-by-step DIY solutions when appropriate",
          "Alert property managers about critical issues",
          "Track issue status from reporting to resolution",
          "Follow up with tenants after repairs",
        ],
      },
    ],
  },
  {
    id: "guest-flow",
    name: "GuestFlow AI",
    tagline: "Complete hospitality guest journey manager",
    description:
      "A conversational AI that manages the entire guest experience, from pre-arrival to post-stay, ensuring personalized service and resolving issues proactively.",
    icon: Hotel,
    iconBg: "bg-green-50",
    iconColor: "text-green-600",
    tags: ["Hospitality", "Guest Experience", "Service"],
    platforms: ["WhatsApp", "Web Interface", "SMS"],
    features: [
      {
        title: "Guest Journey Management",
        description:
          "Handles all touchpoints throughout the guest's stay with personalized communication",
        icon: Star,
        steps: [
          "Send personalized pre-arrival information and check-in instructions",
          "Handle room service and amenity requests during stay",
          "Provide local recommendations and concierge services",
          "Collect feedback and send post-stay communications",
        ],
      },
      {
        title: "Issue Resolution & Service Recovery",
        description:
          "Proactively identifies and resolves guest concerns to ensure satisfaction",
        icon: MessagesSquare,
        steps: [
          "Monitor guest sentiment through conversations",
          "Escalate urgent issues to staff when necessary",
          "Offer immediate solutions within service parameters",
          "Follow up to ensure guest satisfaction",
        ],
      },
    ],
  },
];

// Platform Badge Component
const PlatformBadge = ({ platform }) => {
  // Platform-specific styling
  const getStyles = (platform) => {
    switch (platform.toLowerCase()) {
      case "whatsapp":
        return {
          bg: "bg-green-100",
          text: "text-green-800",
          icon: () => (
            <svg
              viewBox="0 0 24 24"
              className="w-3 h-3 mr-1"
              fill="currentColor"
            >
              <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
            </svg>
          ),
        };
      case "web interface":
        return {
          bg: "bg-blue-100",
          text: "text-blue-800",
          icon: () => <MessageSquare className="w-3 h-3 mr-1" />,
        };
      case "voice (coming soon)":
        return {
          bg: "bg-purple-100",
          text: "text-purple-800",
          icon: () => <MessagesSquare className="w-3 h-3 mr-1" />,
        };
      case "sms":
        return {
          bg: "bg-gray-100",
          text: "text-gray-800",
          icon: () => <MessageSquare className="w-3 h-3 mr-1" />,
        };
      default:
        return {
          bg: "bg-gray-100",
          text: "text-gray-800",
          icon: () => <Tag className="w-3 h-3 mr-1" />,
        };
    }
  };

  const styles = getStyles(platform);

  return (
    <span
      className={`${styles.bg} ${styles.text} text-xs px-2 py-1 rounded-full inline-flex items-center`}
    >
      {styles.icon()}
      {platform}
    </span>
  );
};

// Main component
const AgentDetailTabs = () => {
  const [activeAgent, setActiveAgent] = useState(agentData[0].id);

  const currentAgent = agentData.find((agent) => agent.id === activeAgent);

  return (
    <div className="w-full py-16 px-4 bg-black">
      <div className="max-w-6xl mx-auto">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto mb-12 text-center">
          <h2 className="font-mono text-2xl sm:text-3xl font-bold mb-3 text-white">
            Conversational AI Solutions
          </h2>
          <p className="text-sm sm:text-base text-gray-400">
            Intelligent assistants that handle complex tasks while providing
            natural, human-like interactions for your real estate and
            hospitality business
          </p>
        </div>

        {/* Agent Tabs */}
        <div className="mb-8 flex justify-center">
          <div className="flex bg-gray-900 rounded-full p-1 overflow-hidden">
            {agentData.map((agent) => {
              const AgentIcon = agent.icon;
              return (
                <button
                  key={agent.id}
                  onClick={() => setActiveAgent(agent.id)}
                  className={`flex items-center gap-2 px-4 py-2.5 rounded-full transition-all duration-300 text-sm ${
                    activeAgent === agent.id
                      ? "bg-white text-black shadow-lg"
                      : "bg-transparent text-gray-400 hover:text-white"
                  }`}
                >
                  <AgentIcon className="w-4 h-4" />
                  <span className="font-mono hidden sm:inline">
                    {agent.name}
                  </span>
                </button>
              );
            })}
          </div>
        </div>

        {/* Agent Detail Card */}
        <div className="bg-white rounded-2xl overflow-hidden shadow-xl">
          {/* Header */}
          <div className="p-6 sm:p-8 border-b border-gray-100">
            <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6">
              <div
                className={`w-16 h-16 ${currentAgent.iconBg} rounded-xl flex items-center justify-center`}
              >
                {React.createElement(currentAgent.icon, {
                  className: `w-8 h-8 ${currentAgent.iconColor}`,
                })}
              </div>

              <div>
                <h3 className="font-mono text-xl sm:text-2xl font-bold mb-1">
                  {currentAgent.name}
                </h3>
                <p className="text-gray-600 text-sm sm:text-base">
                  {currentAgent.tagline}
                </p>

                <div className="mt-3 flex flex-wrap gap-2">
                  {currentAgent.platforms.map((platform) => (
                    <PlatformBadge key={platform} platform={platform} />
                  ))}
                </div>
              </div>
            </div>

            <p className="mt-4 text-gray-700 text-sm sm:text-base">
              {currentAgent.description}
            </p>
          </div>

          {/* Features */}
          <div className="p-6 sm:p-8">
            <h4 className="font-mono text-lg font-bold mb-4">
              Key Capabilities
            </h4>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {currentAgent.features.map((feature) => (
                <div
                  key={feature.title}
                  className="border border-gray-100 rounded-xl p-5 hover:shadow-md transition-all group"
                >
                  <div className="flex items-start gap-3 mb-4">
                    {React.createElement(feature.icon, {
                      className: "w-5 h-5 text-blue-600",
                    })}
                    <h5 className="font-mono font-bold text-base">
                      {feature.title}
                    </h5>
                  </div>

                  <p className="text-sm text-gray-600 mb-4">
                    {feature.description}
                  </p>

                  <div className="bg-gray-50 rounded-lg p-3">
                    <h6 className="text-xs text-gray-500 mb-2 font-mono">
                      Process Flow
                    </h6>
                    <ul className="space-y-2">
                      {feature.steps.map((step, index) => (
                        <li
                          key={index}
                          className="flex items-start gap-2 text-xs text-gray-700"
                        >
                          <span className="w-4 h-4 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0 mt-0.5 text-blue-700 text-xs">
                            {index + 1}
                          </span>
                          <span>{step}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Call to Action */}
          <div className="bg-gray-50 p-6 sm:p-8 border-t border-gray-100">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
              <div>
                <h5 className="font-mono font-bold text-base mb-1">
                  Ready to implement {currentAgent.name}?
                </h5>
                <p className="text-sm text-gray-600">
                  Streamline your operations and enhance customer experience
                </p>
              </div>

              <button className="bg-black hover:bg-gray-800 text-white px-5 py-3 rounded-lg font-mono transition-colors flex items-center justify-center gap-2 text-sm group relative overflow-hidden self-start">
                <span className="absolute inset-0 w-0 bg-blue-600 transition-all duration-300 ease-out group-hover:w-full"></span>
                <span className="relative z-10">Get Started</span>
                <ArrowRight className="w-4 h-4 relative z-10" />
              </button>
            </div>
          </div>
        </div>

        {/* Tags Section */}
        <div className="mt-8 flex flex-wrap justify-center gap-2">
          {currentAgent.tags.map((tag) => (
            <span
              key={tag}
              className="inline-block px-3 py-1.5 bg-gray-800 text-gray-300 rounded-full text-xs font-mono"
            >
              #{tag.toLowerCase().replace(/\s+/g, "_")}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AgentDetailTabs;
