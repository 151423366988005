import React, { useState, useRef } from "react";
import {
  Workflow,
  Zap,
  Bot,
  Settings,
  Building,
  Hotel,
  Users,
  Calendar,
  MessageSquare,
  FileText,
  CheckCircle,
  LineChart,
  Lightbulb,
  ArrowRight,
} from "lucide-react";
import Navigation from "../../navigation/Navigation";
import Footer from "../../components/footer/Footer";

// Mock data for service categories
const serviceCategories = [
  {
    id: "process-automation",
    title: "Process Automation",
    description: "Streamline repetitive workflows and eliminate manual tasks",
    icon: Workflow,
    color: "blue",
  },
  {
    id: "intelligent-assistants",
    title: "Intelligent Assistants",
    description: "AI-powered virtual assistants for specialized business tasks",
    icon: Bot,
    color: "green",
  },
  {
    id: "data-insights",
    title: "Data Insights & Analytics",
    description: "Transform business data into actionable intelligence",
    icon: LineChart,
    color: "purple",
  },
];

// Example use cases for each industry
const useCases = {
  realEstate: [
    {
      title: "Automated Listing Management",
      description:
        "Synchronize property listings across multiple platforms, automatically update prices, and manage availability in real-time.",
      benefits: [
        "Save 15+ hours per week",
        "Eliminate data entry errors",
        "Ensure consistent information across channels",
      ],
      tools: ["n8n", "Make", "Property APIs"],
      icon: Building,
    },
    {
      title: "Intelligent Lead Qualification",
      description:
        "Automatically qualify and score leads, route them to the right agents, and trigger personalized follow-up sequences.",
      benefits: [
        "Increase conversion rates by 35%",
        "Reduce response time to under 5 minutes",
        "Focus agent time on high-value leads",
      ],
      tools: ["Claude AI", "Zapier", "CRM integration"],
      icon: Users,
    },
    {
      title: "Smart Document Processing",
      description:
        "Extract data from contracts, applications, and other documents, then automatically populate your systems and trigger relevant workflows.",
      benefits: [
        "Reduce processing time by 80%",
        "Eliminate manual data entry",
        "Improve accuracy to over 98%",
      ],
      tools: ["ChatGPT", "OCR technology", "n8n"],
      icon: FileText,
    },
  ],
  hospitality: [
    {
      title: "Guest Communication Automation",
      description:
        "Deliver personalized pre-arrival information, in-stay assistance, and post-stay follow-ups through automated messaging flows.",
      benefits: [
        "Enhance guest experience scores by 40%",
        "Reduce front desk inquiries by 30%",
        "Increase review collection by 65%",
      ],
      tools: ["DeepSeek", "Make", "Messaging APIs"],
      icon: MessageSquare,
    },
    {
      title: "Dynamic Pricing Optimization",
      description:
        "Automatically adjust room rates based on demand, competitor pricing, events, and other factors to maximize revenue.",
      benefits: [
        "Increase RevPAR by up to 15%",
        "Respond to market changes in real-time",
        "Optimize occupancy rates",
      ],
      tools: ["AI pricing models", "n8n", "Market data APIs"],
      icon: LineChart,
    },
    {
      title: "Staff & Resource Scheduling",
      description:
        "Optimize staff schedules, housekeeping routes, and resource allocation based on occupancy, guest preferences, and historical patterns.",
      benefits: [
        "Reduce labor costs by 12%",
        "Improve operational efficiency",
        "Enhance service delivery timing",
      ],
      tools: ["Zapier", "Make", "Claude AI"],
      icon: Calendar,
    },
  ],
};

const AutomationServicesPage = () => {
  const [activeIndustry, setActiveIndustry] = useState("realEstate");
  const [visibleSection, setVisibleSection] = useState(null);
  const sectionRefs = {
    hero: useRef(null),
    services: useRef(null),
    industries: useRef(null),
    useCases: useRef(null),
    process: useRef(null),
    technologies: useRef(null),
    cta: useRef(null),
  };

  const scrollToSection = (section) => {
    setVisibleSection(section);
    sectionRefs[section].current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-black min-h-screen">
      <Navigation />

      {/* Hero Section */}
      <div
        ref={sectionRefs.hero}
        className="w-[99%] mx-auto bg-white rounded-2xl overflow-hidden mt-4"
      >
        <div className="relative">
          {/* Hero Background with Grid Pattern */}
          <div
            className="min-h-[500px] w-full bg-gradient-to-br from-white to-blue-50 flex items-center justify-center relative"
            style={{
              backgroundImage:
                "radial-gradient(circle at 1px 1px, #f0f0f0 1px, transparent 0)",
              backgroundSize: "40px 40px",
            }}
          >
            {/* Accent Elements */}
            <div className="absolute top-1/4 left-1/6 w-24 h-24 bg-blue-100 rounded-full opacity-30 blur-xl"></div>
            <div className="absolute bottom-1/4 right-1/6 w-32 h-32 bg-blue-200 rounded-full opacity-20 blur-xl"></div>

            {/* Animated workflow lines */}
            <div className="absolute inset-0 overflow-hidden opacity-10 pointer-events-none">
              <svg
                className="absolute top-1/4 right-1/4 w-64 h-64 text-blue-400"
                viewBox="0 0 100 100"
              >
                <path
                  d="M10,30 Q30,5 50,30 T90,30"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                  strokeDasharray="5,5"
                  className="animate-dash"
                />
                <path
                  d="M10,50 Q50,20 90,50"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                  strokeDasharray="5,5"
                  className="animate-dash-reverse"
                />
                <path
                  d="M10,70 Q50,40 90,70"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="0.5"
                  strokeDasharray="5,5"
                  className="animate-dash-slow"
                />
              </svg>
            </div>

            {/* Content */}
            <div className="max-w-6xl mx-auto px-6 py-16 z-10">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                <div className="space-y-8">
                  <div className="inline-flex items-center px-3 py-1.5 bg-blue-50 rounded-full">
                    <div className="w-2 h-2 rounded-full bg-blue-600 mr-2"></div>
                    <span className="text-xs font-mono text-blue-700">
                      Serving South Africa
                    </span>
                  </div>

                  <h1 className="font-mono text-4xl md:text-5xl font-bold text-black tracking-tight">
                    AI & Automation <br />
                    <span className="text-blue-600">for your business</span>
                  </h1>

                  <p className="text-gray-600 text-lg">
                    Streamline operations, enhance customer experiences, and
                    drive growth with tailored automation solutions for real
                    estate and hospitality businesses.
                  </p>

                  <div className="flex flex-wrap gap-4">
                    <button
                      onClick={() => scrollToSection("useCases")}
                      className="px-6 py-3 bg-black hover:bg-blue-700 text-white font-mono rounded-lg transition-colors flex items-center gap-2"
                    >
                      <Lightbulb className="w-5 h-5" />
                      <span>View Solutions</span>
                    </button>

                    <button
                      onClick={() => scrollToSection("process")}
                      className="px-6 py-3 bg-white text-black border border-gray-200 hover:border-blue-200 font-mono rounded-lg transition-colors flex items-center gap-2"
                    >
                      <Workflow className="w-5 h-5" />
                      <span>Our Process</span>
                    </button>
                  </div>
                </div>

                <div className="relative hidden lg:block">
                  {/* Automation Graphic Illustration */}
                  <div className="relative bg-white rounded-xl border border-gray-100 shadow-lg p-6 z-10">
                    <div className="flex items-center justify-between mb-6">
                      <div className="flex gap-1.5">
                        <div className="w-3 h-3 bg-red-400 rounded-full"></div>
                        <div className="w-3 h-3 bg-yellow-400 rounded-full"></div>
                        <div className="w-3 h-3 bg-green-400 rounded-full"></div>
                      </div>
                      <div className="text-xs font-mono text-gray-400">
                        automation_flow.n8n
                      </div>
                    </div>

                    {/* Workflow Visualization */}
                    <div className="flex items-center justify-between mb-10">
                      <div className="flex flex-col items-center">
                        <div className="w-14 h-14 rounded-lg bg-blue-50 flex items-center justify-center">
                          <MessageSquare className="w-6 h-6 text-blue-600" />
                        </div>
                        <span className="text-xs font-mono mt-2">Trigger</span>
                      </div>

                      <svg width="40" height="10" className="text-gray-300">
                        <line
                          x1="0"
                          y1="5"
                          x2="40"
                          y2="5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeDasharray="4,2"
                        />
                        <polyline
                          points="30,0 40,5 30,10"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                      </svg>

                      <div className="flex flex-col items-center">
                        <div className="w-14 h-14 rounded-lg bg-blue-50 flex items-center justify-center">
                          <Bot className="w-6 h-6 text-blue-600" />
                        </div>
                        <span className="text-xs font-mono mt-2">Process</span>
                      </div>

                      <svg width="40" height="10" className="text-gray-300">
                        <line
                          x1="0"
                          y1="5"
                          x2="40"
                          y2="5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeDasharray="4,2"
                        />
                        <polyline
                          points="30,0 40,5 30,10"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                      </svg>

                      <div className="flex flex-col items-center">
                        <div className="w-14 h-14 rounded-lg bg-blue-50 flex items-center justify-center">
                          <Zap className="w-6 h-6 text-blue-600" />
                        </div>
                        <span className="text-xs font-mono mt-2">Action</span>
                      </div>
                    </div>

                    {/* Sample Code Block */}
                    <div className="bg-gray-900 rounded-lg p-4 text-white font-mono text-xs overflow-hidden">
                      <div className="text-gray-400">// Automation sample</div>
                      <div className="text-blue-400">async function</div>
                      <div>
                        <span className="text-green-400">processLeadData</span>
                        (data) {"{"}
                      </div>
                      <div className="ml-4">
                        const qualified ={" "}
                        <span className="text-purple-400">await</span>{" "}
                        ai.analyze(data);
                      </div>
                      <div className="ml-4">
                        <span className="text-blue-400">if</span> (qualified){" "}
                        {"{"}
                      </div>
                      <div className="ml-8">
                        <span className="text-green-400">notifyAgent</span>
                        (data);
                      </div>
                      <div className="ml-8">
                        <span className="text-orange-400">
                          startFollowUpSequence
                        </span>
                        ();
                      </div>
                      <div className="ml-4">
                        {"}"} <span className="text-blue-400">else</span> {"{"}
                      </div>
                      <div className="ml-8">
                        <span className="text-orange-400">
                          addToNurturingCampaign
                        </span>
                        ();
                      </div>
                      <div className="ml-4">{"}"}</div>
                      <div>{"}"}</div>
                    </div>
                  </div>

                  {/* Decoration elements */}
                  <div className="absolute -top-4 -right-4 w-32 h-32 bg-blue-50 rounded-full -z-10"></div>
                  <div className="absolute -bottom-6 -left-6 w-24 h-24 bg-gray-50 rounded-full -z-10"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Key Benefits Section */}
      <div className="w-[99%] mx-auto bg-white rounded-2xl mt-4 p-8 sm:p-12">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="font-mono text-3xl font-bold mb-4">
              Transforming Operations with Smart Technology
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Our AI & automation solutions help South African real estate and
              hospitality businesses eliminate inefficiencies, reduce costs, and
              deliver exceptional experiences.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Benefit 1 */}
            <div className="bg-gray-50 rounded-xl p-6 hover:shadow-md transition-all group">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 group-hover:bg-blue-200 transition-colors">
                <Zap className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="font-mono text-lg font-bold mb-2">
                Boost Operational Efficiency
              </h3>
              <p className="text-gray-600 text-sm">
                Automate repetitive tasks and workflows to save time, reduce
                errors, and allow your team to focus on high-value activities
                that drive business growth.
              </p>
              <div className="mt-4 pt-4 border-t border-gray-200">
                <div className="text-sm text-gray-500">
                  <span className="font-bold text-blue-600">85%</span> reduction
                  in manual processing time
                </div>
              </div>
            </div>

            {/* Benefit 2 */}
            <div className="bg-gray-50 rounded-xl p-6 hover:shadow-md transition-all group">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 group-hover:bg-blue-200 transition-colors">
                <Users className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="font-mono text-lg font-bold mb-2">
                Enhance Customer Experience
              </h3>
              <p className="text-gray-600 text-sm">
                Deliver faster responses, personalized interactions, and
                seamless service through intelligent automation that anticipates
                needs and resolves issues proactively.
              </p>
              <div className="mt-4 pt-4 border-t border-gray-200">
                <div className="text-sm text-gray-500">
                  <span className="font-bold text-blue-600">40%</span>{" "}
                  improvement in customer satisfaction scores
                </div>
              </div>
            </div>

            {/* Benefit 3 */}
            <div className="bg-gray-50 rounded-xl p-6 hover:shadow-md transition-all group">
              <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mb-4 group-hover:bg-blue-200 transition-colors">
                <LineChart className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="font-mono text-lg font-bold mb-2">
                Data-Driven Decisions
              </h3>
              <p className="text-gray-600 text-sm">
                Transform raw business data into actionable insights with
                AI-powered analytics that help you identify trends,
                opportunities, and areas for improvement.
              </p>
              <div className="mt-4 pt-4 border-t border-gray-200">
                <div className="text-sm text-gray-500">
                  <span className="font-bold text-blue-600">27%</span> revenue
                  growth through optimized operations
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Our Services Section */}
      <div
        ref={sectionRefs.services}
        className="w-[99%] mx-auto bg-white rounded-2xl mt-4 p-8 sm:p-12"
      >
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="font-mono text-3xl font-bold mb-4">Our Services</h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              We offer a comprehensive suite of AI and automation services
              tailored to the unique needs of real estate and hospitality
              businesses in South Africa.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {serviceCategories.map((category) => (
              <div
                key={category.id}
                className="border border-gray-100 rounded-xl p-6 hover:shadow-md transition-all group"
              >
                <div
                  className={`w-14 h-14 bg-${category.color}-50 rounded-lg flex items-center justify-center mb-6 group-hover:bg-${category.color}-100 transition-colors`}
                >
                  <category.icon
                    className={`w-7 h-7 text-${category.color}-600`}
                  />
                </div>
                <h3 className="font-mono text-xl font-bold mb-3">
                  {category.title}
                </h3>
                <p className="text-gray-600 mb-6">{category.description}</p>

                {/* Service Features */}
                <ul className="space-y-2.5 mb-6">
                  {category.id === "process-automation" && (
                    <>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Workflow automation with n8n & Make
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Cross-platform data synchronization
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Document processing & management
                        </span>
                      </li>
                    </>
                  )}

                  {category.id === "intelligent-assistants" && (
                    <>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          AI-powered chatbots for client engagement
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Virtual assistants for operational tasks
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          24/7 customer support automation
                        </span>
                      </li>
                    </>
                  )}

                  {category.id === "data-insights" && (
                    <>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Business intelligence dashboards
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Predictive analytics for decision making
                        </span>
                      </li>
                      <li className="flex items-start gap-2">
                        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        <span className="text-sm text-gray-600">
                          Performance monitoring & optimization
                        </span>
                      </li>
                    </>
                  )}
                </ul>

                <button className="text-blue-600 font-mono text-sm flex items-center gap-1.5 group-hover:text-blue-700 transition-colors">
                  <span>Learn more</span>
                  <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Industries We Serve */}
      <div
        ref={sectionRefs.industries}
        className="w-[99%] mx-auto bg-white rounded-2xl mt-4 p-8 sm:p-12"
      >
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="font-mono text-3xl font-bold mb-4">
              Industries We Serve
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              We specialize in delivering tailored automation solutions for real
              estate and hospitality businesses throughout South Africa.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Real Estate */}
            <div
              className={`border border-gray-100 rounded-xl p-6 hover:border-blue-200 transition-all cursor-pointer ${
                activeIndustry === "realEstate"
                  ? "bg-blue-50 border-blue-200"
                  : "bg-white"
              }`}
              onClick={() => setActiveIndustry("realEstate")}
            >
              <div className="flex items-start gap-4">
                <div className="w-14 h-14 bg-blue-100 rounded-lg flex items-center justify-center flex-shrink-0">
                  <Building className="w-7 h-7 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-mono text-xl font-bold mb-2">
                    Real Estate
                  </h3>
                  <p className="text-gray-600 text-sm mb-4">
                    Streamline property management, accelerate sales processes,
                    and enhance client experiences with intelligent automation
                    solutions.
                  </p>
                  <div className="flex flex-wrap gap-2">
                    <span className="bg-white px-2.5 py-1 rounded-full text-xs font-mono border border-gray-100">
                      Property Management
                    </span>
                    <span className="bg-white px-2.5 py-1 rounded-full text-xs font-mono border border-gray-100">
                      Real Estate Agencies
                    </span>
                    <span className="bg-white px-2.5 py-1 rounded-full text-xs font-mono border border-gray-100">
                      Property Developers
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Hospitality */}
            <div
              className={`border border-gray-100 rounded-xl p-6 hover:border-blue-200 transition-all cursor-pointer ${
                activeIndustry === "hospitality"
                  ? "bg-blue-50 border-blue-200"
                  : "bg-white"
              }`}
              onClick={() => setActiveIndustry("hospitality")}
            >
              <div className="flex items-start gap-4">
                <div className="w-14 h-14 bg-blue-100 rounded-lg flex items-center justify-center flex-shrink-0">
                  <Hotel className="w-7 h-7 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-mono text-xl font-bold mb-2">
                    Hospitality
                  </h3>
                  <p className="text-gray-600 text-sm mb-4">
                    Optimize hotel operations, enhance guest experiences, and
                    maximize revenue with tailored AI and automation solutions.
                  </p>
                  <div className="flex flex-wrap gap-2">
                    <span className="bg-white px-2.5 py-1 rounded-full text-xs font-mono border border-gray-100">
                      Hotels & Resorts
                    </span>
                    <span className="bg-white px-2.5 py-1 rounded-full text-xs font-mono border border-gray-100">
                      Guest Houses
                    </span>
                    <span className="bg-white px-2.5 py-1 rounded-full text-xs font-mono border border-gray-100">
                      Vacation Rentals
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Use Cases Section */}
      <div
        ref={sectionRefs.useCases}
        className="w-[99%] mx-auto bg-white rounded-2xl mt-4 p-8 sm:p-12"
      >
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="font-mono text-3xl font-bold mb-4">
              Practical Solutions for Real Problems
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              See how our AI and automation solutions address specific
              challenges in
              {activeIndustry === "realEstate"
                ? " real estate"
                : " hospitality"}{" "}
              businesses.
            </p>

            <div className="flex justify-center gap-4 mt-6">
              <button
                onClick={() => setActiveIndustry("realEstate")}
                className={`px-4 py-2 rounded-lg font-mono text-sm ${
                  activeIndustry === "realEstate"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-50 text-gray-600 hover:bg-gray-100"
                } transition-colors`}
              >
                Real Estate Solutions
              </button>
              <button
                onClick={() => setActiveIndustry("hospitality")}
                className={`px-4 py-2 rounded-lg font-mono text-sm ${
                  activeIndustry === "hospitality"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-50 text-gray-600 hover:bg-gray-100"
                } transition-colors`}
              >
                Hospitality Solutions
              </button>
            </div>
          </div>

          <div className="space-y-12">
            {useCases[activeIndustry].map((useCase, index) => (
              <div
                key={`${activeIndustry}-${index}`}
                className="border border-gray-100 rounded-xl overflow-hidden shadow-sm group hover:shadow-md transition-all"
              >
                <div className="grid grid-cols-1 md:grid-cols-5">
                  {/* Left Column - Illustration */}
                  <div className="bg-blue-50 p-6 md:p-8 flex items-center justify-center md:col-span-2">
                    <div className="relative">
                      <div className="w-24 h-24 bg-white rounded-xl flex items-center justify-center shadow-sm relative z-10 group-hover:scale-110 transition-transform">
                        <useCase.icon className="w-12 h-12 text-blue-600" />
                      </div>
                      <div className="absolute -inset-4 bg-blue-100 rounded-full opacity-30 group-hover:opacity-60 transition-opacity blur-xl"></div>
                    </div>
                  </div>

                  {/* Right Column - Content */}
                  <div className="p-6 md:p-8 md:col-span-3">
                    <h3 className="font-mono text-xl font-bold mb-3">
                      {useCase.title}
                    </h3>
                    <p className="text-gray-600 mb-6">{useCase.description}</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {/* Benefits */}
                      <div>
                        <h4 className="font-mono font-bold text-sm mb-3 text-blue-700">
                          Key Benefits
                        </h4>
                        <ul className="space-y-2">
                          {useCase.benefits.map((benefit, i) => (
                            <li key={i} className="flex items-start gap-2">
                              <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0 mt-0.5" />
                              <span className="text-sm text-gray-600">
                                {benefit}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Tools Used */}
                      <div>
                        <h4 className="font-mono font-bold text-sm mb-3 text-blue-700">
                          Technologies Used
                        </h4>
                        <div className="flex flex-wrap gap-2">
                          {useCase.tools.map((tool, i) => (
                            <span
                              key={i}
                              className="bg-blue-50 px-2.5 py-1 rounded-full text-xs font-mono text-blue-700"
                            >
                              {tool}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="mt-6 pt-6 border-t border-gray-100">
                      <button className="text-blue-600 font-mono text-sm flex items-center gap-1.5 group-hover:text-blue-700 transition-colors">
                        <span>Request a demo</span>
                        <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Our Process Section */}
      <div
        ref={sectionRefs.process}
        className="w-[99%] mx-auto bg-white rounded-2xl mt-4 p-8 sm:p-12"
      >
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="font-mono text-3xl font-bold mb-4">
              Our Implementation Process
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              We follow a structured approach to understand your business needs,
              design tailored solutions, and ensure successful implementation
              with ongoing support.
            </p>
          </div>

          <div className="relative">
            {/* Process Timeline */}
            <div className="absolute left-[50%] top-0 bottom-0 w-1 bg-blue-100 hidden md:block"></div>

            <div className="space-y-24">
              {/* Step 1 - Discovery */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                <div className="md:text-right">
                  <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <h3 className="font-mono text-xl font-bold mb-4">
                      01. Discovery
                    </h3>
                    <p className="text-gray-600 mb-4">
                      We start by understanding your business processes, pain
                      points, and objectives through in-depth discussions and
                      workflow analysis.
                    </p>
                    <ul className="space-y-2 md:ml-auto md:text-right">
                      <li className="flex items-center gap-2 md:justify-end text-sm text-gray-600">
                        <span>Process audit & workflow mapping</span>
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0 md:order-last order-first" />
                      </li>
                      <li className="flex items-center gap-2 md:justify-end text-sm text-gray-600">
                        <span>Technology assessment</span>
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0 md:order-last order-first" />
                      </li>
                      <li className="flex items-center gap-2 md:justify-end text-sm text-gray-600">
                        <span>Goal & KPI definition</span>
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0 md:order-last order-first" />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="hidden md:block"></div>

                {/* Timeline Node */}
                <div className="absolute left-[50%] top-6 w-8 h-8 bg-blue-600 rounded-full transform -translate-x-1/2 hidden md:flex items-center justify-center">
                  <span className="text-white font-mono font-bold text-sm">
                    1
                  </span>
                </div>
              </div>

              {/* Step 2 - Design */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                <div className="hidden md:block"></div>

                <div>
                  <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <h3 className="font-mono text-xl font-bold mb-4">
                      02. Solution Design
                    </h3>
                    <p className="text-gray-600 mb-4">
                      Our team creates a tailored automation blueprint that
                      addresses your specific challenges and leverages the most
                      appropriate technologies.
                    </p>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2 text-sm text-gray-600">
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                        <span>Custom automation architecture</span>
                      </li>
                      <li className="flex items-center gap-2 text-sm text-gray-600">
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                        <span>Technology stack selection</span>
                      </li>
                      <li className="flex items-center gap-2 text-sm text-gray-600">
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                        <span>Integration planning</span>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Timeline Node */}
                <div className="absolute left-[50%] top-6 w-8 h-8 bg-blue-600 rounded-full transform -translate-x-1/2 hidden md:flex items-center justify-center">
                  <span className="text-white font-mono font-bold text-sm">
                    2
                  </span>
                </div>
              </div>

              {/* Step 3 - Implementation */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                <div className="md:text-right">
                  <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <h3 className="font-mono text-xl font-bold mb-4">
                      03. Implementation
                    </h3>
                    <p className="text-gray-600 mb-4">
                      We build and deploy your custom automation solution,
                      integrating with your existing systems and ensuring
                      minimal disruption to your operations.
                    </p>
                    <ul className="space-y-2 md:ml-auto md:text-right">
                      <li className="flex items-center gap-2 md:justify-end text-sm text-gray-600">
                        <span>Agile development process</span>
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0 md:order-last order-first" />
                      </li>
                      <li className="flex items-center gap-2 md:justify-end text-sm text-gray-600">
                        <span>Regular progress updates</span>
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0 md:order-last order-first" />
                      </li>
                      <li className="flex items-center gap-2 md:justify-end text-sm text-gray-600">
                        <span>Thorough testing protocols</span>
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0 md:order-last order-first" />
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="hidden md:block"></div>

                {/* Timeline Node */}
                <div className="absolute left-[50%] top-6 w-8 h-8 bg-blue-600 rounded-full transform -translate-x-1/2 hidden md:flex items-center justify-center">
                  <span className="text-white font-mono font-bold text-sm">
                    3
                  </span>
                </div>
              </div>

              {/* Step 4 - Training & Support */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative">
                <div className="hidden md:block"></div>

                <div>
                  <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm hover:shadow-md transition-all">
                    <h3 className="font-mono text-xl font-bold mb-4">
                      04. Training & Ongoing Support
                    </h3>
                    <p className="text-gray-600 mb-4">
                      We provide comprehensive training for your team and offer
                      continuous support to ensure your automation solution
                      delivers maximum value.
                    </p>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2 text-sm text-gray-600">
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                        <span>Hands-on training sessions</span>
                      </li>
                      <li className="flex items-center gap-2 text-sm text-gray-600">
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                        <span>Performance monitoring</span>
                      </li>
                      <li className="flex items-center gap-2 text-sm text-gray-600">
                        <CheckCircle className="w-4 h-4 text-green-500 flex-shrink-0" />
                        <span>Continuous optimization</span>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Timeline Node */}
                <div className="absolute left-[50%] top-6 w-8 h-8 bg-blue-600 rounded-full transform -translate-x-1/2 hidden md:flex items-center justify-center">
                  <span className="text-white font-mono font-bold text-sm">
                    4
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section for Automation */}
      <div className="w-[99%] mx-auto bg-white rounded-2xl mt-4 p-8 sm:p-12">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="font-mono text-3xl font-bold mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Get answers to common questions about our AI and automation
              services.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* FAQ Item 1 */}
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-mono text-lg font-bold mb-3">
                How long does implementation typically take?
              </h3>
              <p className="text-gray-600 text-sm">
                The timeline varies based on the complexity of your automation
                needs. Simple workflows can be implemented in 2-4 weeks, while
                more complex solutions may take 6-8 weeks. We provide a detailed
                timeline during the discovery phase.
              </p>
            </div>

            {/* FAQ Item 2 */}
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-mono text-lg font-bold mb-3">
                Do I need technical knowledge to use the solutions?
              </h3>
              <p className="text-gray-600 text-sm">
                No technical expertise is required. We design user-friendly
                interfaces and provide comprehensive training for your team. Our
                solutions are built to be easily managed by non-technical staff.
              </p>
            </div>

            {/* FAQ Item 3 */}
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-mono text-lg font-bold mb-3">
                What kind of ROI can I expect?
              </h3>
              <p className="text-gray-600 text-sm">
                Our clients typically see ROI within 3-6 months. This includes
                time savings, reduced operational costs, improved customer
                satisfaction, and increased revenue through optimized processes.
                We'll help you track these metrics.
              </p>
            </div>

            {/* FAQ Item 4 */}
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-mono text-lg font-bold mb-3">
                Is my data secure with your solutions?
              </h3>
              <p className="text-gray-600 text-sm">
                Yes, security is our top priority. We implement
                industry-standard security protocols, data encryption, and
                access controls. All solutions comply with relevant privacy
                regulations, and we can sign NDAs if required.
              </p>
            </div>

            {/* FAQ Item 5 */}
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-mono text-lg font-bold mb-3">
                Can your solutions integrate with my existing systems?
              </h3>
              <p className="text-gray-600 text-sm">
                Absolutely. We specialize in integrating with popular real
                estate and hospitality platforms, as well as general business
                tools. If there's no existing connector, we can develop custom
                integrations for your specific needs.
              </p>
            </div>

            {/* FAQ Item 6 */}
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-mono text-lg font-bold mb-3">
                What ongoing support do you provide?
              </h3>
              <p className="text-gray-600 text-sm">
                We offer various support packages including system monitoring,
                regular maintenance, troubleshooting, and continuous
                optimization. Our team is available to address any issues and
                help you maximize the value of your automation investment.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div
        ref={sectionRefs.cta}
        className="w-[99%] mx-auto bg-white rounded-2xl mt-4 p-8 sm:p-12 mb-4"
      >
        <div className="max-w-6xl mx-auto">
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl p-8 sm:p-12">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
              <div>
                <h2 className="font-mono text-3xl font-bold mb-4">
                  Ready to Transform Your Business?
                </h2>
                <p className="text-gray-600 mb-6">
                  Let's discuss how our AI and automation solutions can
                  streamline your operations, enhance customer experiences, and
                  drive growth for your business.
                </p>

                <div className="space-y-4">
                  <div className="flex items-start gap-3">
                    <div className="w-6 h-6 rounded-full bg-blue-200 flex items-center justify-center flex-shrink-0 mt-0.5">
                      <CheckCircle className="w-4 h-4 text-blue-700" />
                    </div>
                    <p className="text-sm text-gray-600">
                      Free consultation to assess your specific needs
                    </p>
                  </div>

                  <div className="flex items-start gap-3">
                    <div className="w-6 h-6 rounded-full bg-blue-200 flex items-center justify-center flex-shrink-0 mt-0.5">
                      <CheckCircle className="w-4 h-4 text-blue-700" />
                    </div>
                    <p className="text-sm text-gray-600">
                      Tailored solutions designed for your business goals
                    </p>
                  </div>

                  <div className="flex items-start gap-3">
                    <div className="w-6 h-6 rounded-full bg-blue-200 flex items-center justify-center flex-shrink-0 mt-0.5">
                      <CheckCircle className="w-4 h-4 text-blue-700" />
                    </div>
                    <p className="text-sm text-gray-600">
                      Ongoing support to ensure maximum ROI
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white p-6 rounded-xl shadow-sm">
                <h3 className="font-mono text-xl font-bold mb-4">
                  Request a Consultation
                </h3>
                <form className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Your first name"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        placeholder="Your last name"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Business Email
                    </label>
                    <input
                      type="email"
                      className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="you@company.com"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      placeholder="Your company"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Industry
                    </label>
                    <select className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent">
                      <option value="">Select your industry</option>
                      <option value="real-estate">Real Estate</option>
                      <option value="hospitality">Hospitality</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Message
                    </label>
                    <textarea
                      className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent h-24"
                      placeholder="Tell us about your automation needs"
                    ></textarea>
                  </div>

                  <button className="w-full bg-black hover:bg-blue-700 text-white font-mono rounded-lg px-6 py-3 transition-colors flex items-center justify-center gap-2">
                    <Zap className="w-5 h-5" />
                    <span>Request Consultation</span>
                  </button>

                  <p className="text-xs text-gray-500 text-center">
                    By submitting this form, you agree to our privacy policy and
                    terms of service.
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AutomationServicesPage;

// Add these custom animations to your global CSS or tailwind config:
/*
@keyframes dash {
  to {
    stroke-dashoffset: 20;
  }
}

@keyframes dash-reverse {
  to {
    stroke-dashoffset: -20;
  }
}

.animate-dash {
  animation: dash 20s linear infinite;
}

.animate-dash-reverse {
  animation: dash-reverse 15s linear infinite;
}

.animate-dash-slow {
  animation: dash 25s linear infinite;
}

.animate-ping-slow {
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.animate-ping-slower {
  animation: ping 3s cubic-bezier(0, 0, 0.2, 1) infinite;
}
*/
