import React, { useState } from "react";
import {
  Workflow,
  Cpu,
  Bot,
  ArrowRight,
  Zap,
  Database,
  LineChart,
  X,
  Lock,
  Upload,
  CalendarDays,
  Users,
  Search,
  Sparkles,
  MessageCircle,
  ChevronsRight,
  AlertCircle,
  ArrowUpRight
} from "lucide-react";

const CustomServicesSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    businessType: "real-estate",
    challenge: "",
    requirements: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would normally send the data to your backend
    console.log("Form submitted:", formData);
    // Show success message or redirect
    alert("Your request has been submitted. We'll be in touch soon!");
    setShowModal(false);
    setFormData({
      name: "",
      email: "",
      company: "",
      businessType: "real-estate",
      challenge: "",
      requirements: ""
    });
  };

  // Custom services data
  const services = [
    {
      id: "internal-ai",
      title: "Internal AI Assistants",
      description: "Custom AI agents that streamline your internal processes and assist your team",
      icon: Bot,
      iconBg: "bg-purple-50",
      iconColor: "text-purple-600",
      benefits: [
        "Reduce manual administrative tasks",
        "Speed up information retrieval",
        "Enhance employee productivity",
        "Automate repetitive workflows"
      ],
      examples: [
        "Internal knowledge base assistant",
        "Document processing automation",
        "Employee onboarding AI",
        "Meeting summary generator"
      ]
    },
    {
      id: "workflow-automation",
      title: "Workflow Automation",
      description: "End-to-end automation solutions that connect your business tools and streamline operations",
      icon: Workflow,
      iconBg: "bg-blue-50",
      iconColor: "text-blue-600",
      benefits: [
        "Eliminate manual data entry",
        "Reduce human error",
        "Create consistent processes",
        "Save hours of repetitive work"
      ],
      examples: [
        "Automated booking confirmations",
        "Document generation workflows",
        "Data synchronization between systems",
        "Approval process automation"
      ]
    },
    {
      id: "analytics-insights",
      title: "AI-Powered Analytics",
      description: "Smart analytics solutions that turn your business data into actionable insights",
      icon: LineChart,
      iconBg: "bg-green-50",
      iconColor: "text-green-600",
      benefits: [
        "Discover hidden patterns in your data",
        "Predict future business trends",
        "Make data-driven decisions",
        "Visualize key performance metrics"
      ],
      examples: [
        "Occupancy prediction models",
        "Customer behavior analysis",
        "Revenue optimization insights",
        "Performance benchmarking"
      ]
    }
  ];

  // Business process use cases
  const businessProcesses = [
    {
      title: "Guest Experience Enhancement",
      for: "hospitality",
      icon: Users,
      description: "Personalized guest interactions using AI to anticipate needs and enhance satisfaction"
    },
    {
      title: "Lead Qualification",
      for: "real-estate",
      icon: Search,
      description: "Automated systems that qualify and route leads based on property preferences and budget"
    },
    {
      title: "Scheduling Optimization",
      for: "both",
      icon: CalendarDays,
      description: "Smart scheduling systems that maximize efficiency and prevent double-bookings"
    },
    {
      title: "Data Integration",
      for: "both",
      icon: Database,
      description: "Seamless connections between your various business systems and data sources"
    }
  ];

  return (
    <div className="w-[98%] mx-auto py-16 px-4 bg-gradient-to-b from-gray-900 to-black rounded-xl mt-16">
      <div className="max-w-6xl mx-auto">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto mb-16 text-center">
          <div className="inline-flex items-center bg-white/10 px-3 py-1 rounded-full text-blue-400 text-sm font-mono mb-4">
            <Sparkles className="w-4 h-4 mr-2" />
            <span>Beyond Pre-Built Solutions</span>
          </div>
          
          <h2 className="font-mono text-3xl font-bold mb-4 text-white">
            Custom AI & Automation Solutions
          </h2>
          
          <p className="text-gray-400 mb-6">
            We don't just offer pre-packaged AI agents. Our team creates tailored AI and automation 
            solutions designed specifically for your unique business challenges.
          </p>
          
          <div className="flex flex-wrap justify-center gap-2 text-xs text-gray-500">
            <span className="flex items-center gap-1">
              <div className="w-1.5 h-1.5 rounded-full bg-purple-500"></div>
              <span>80% No-Code</span>
            </span>
            <span className="flex items-center gap-1">
              <div className="w-1.5 h-1.5 rounded-full bg-blue-500"></div>
              <span>Rapid Implementation</span>
            </span>
            <span className="flex items-center gap-1">
              <div className="w-1.5 h-1.5 rounded-full bg-green-500"></div>
              <span>Custom Integrations</span>
            </span>
          </div>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8 mb-16">
          {services.map(service => (
            <div 
              key={service.id}
              className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-2xl p-6 hover:bg-white/10 transition-all group"
            >
              <div className="flex items-start gap-4 mb-6">
                <div className={`w-12 h-12 ${service.iconBg} rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform`}>
                  {React.createElement(service.icon, {
                    className: `w-6 h-6 ${service.iconColor}`
                  })}
                </div>
                
                <div>
                  <h3 className="font-mono text-xl font-bold text-white mb-1">{service.title}</h3>
                  <p className="text-sm text-gray-400">{service.description}</p>
                </div>
              </div>
              
              <div className="pl-2 mb-6">
                <h4 className="text-xs uppercase text-gray-500 tracking-wider mb-2">Benefits</h4>
                <ul className="space-y-2">
                  {service.benefits.map((benefit, index) => (
                    <li key={index} className="flex items-start gap-2 text-sm text-gray-300">
                      <Zap className="w-4 h-4 text-blue-400 flex-shrink-0 mt-0.5" />
                      <span>{benefit}</span>
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className="pl-2">
                <h4 className="text-xs uppercase text-gray-500 tracking-wider mb-2">Examples</h4>
                <ul className="space-y-2">
                  {service.examples.map((example, index) => (
                    <li key={index} className="flex items-start gap-2 text-sm text-gray-400">
                      <ArrowUpRight className="w-3.5 h-3.5 text-gray-500 flex-shrink-0 mt-0.5" />
                      <span>{example}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Business Process Use Cases */}
        <div className="max-w-4xl mx-auto mb-16">
          <h3 className="font-mono text-xl font-bold text-white text-center mb-8">
            Common Business Processes We Automate
          </h3>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {businessProcesses.map((process, index) => (
              <div 
                key={index}
                className="bg-white/5 border border-white/10 rounded-xl p-4 flex items-start gap-3"
              >
                <div className="w-10 h-10 bg-white/10 rounded-lg flex items-center justify-center flex-shrink-0">
                  {React.createElement(process.icon, {
                    className: "w-5 h-5 text-blue-400"
                  })}
                </div>
                
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <h4 className="font-medium text-white">{process.title}</h4>
                    <span className={`text-xs py-0.5 px-1.5 rounded ${
                      process.for === 'real-estate' 
                        ? 'bg-amber-900/30 text-amber-400' 
                        : process.for === 'hospitality' 
                          ? 'bg-green-900/30 text-green-400'
                          : 'bg-blue-900/30 text-blue-400'
                    }`}>
                      {process.for === 'both' ? 'All Industries' : process.for === 'real-estate' ? 'Real Estate' : 'Hospitality'}
                    </span>
                  </div>
                  <p className="text-sm text-gray-400">{process.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <div className="bg-gradient-to-br from-blue-900/20 to-purple-900/20 border border-white/10 rounded-2xl p-8 text-center max-w-3xl mx-auto backdrop-blur-sm">
          <div className="mb-6">
            <Cpu className="w-12 h-12 text-blue-400 mx-auto mb-4" />
            <h3 className="font-mono text-2xl font-bold text-white mb-2">
              Have a Specific Challenge?
            </h3>
            <p className="text-gray-400 max-w-lg mx-auto">
              Tell us about your business needs, and our team will design a custom AI or automation 
              solution that addresses your specific challenges.
            </p>
          </div>
          
          <button 
            onClick={() => setShowModal(true)}
            className="inline-flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg px-6 py-3 transition-colors"
          >
            <MessageCircle className="w-5 h-5" />
            <span>Request Custom Solution</span>
          </button>
        </div>

        {/* Philosophy Statement */}
        <div className="mt-16 text-center">
          <p className="text-sm text-gray-500 max-w-xl mx-auto">
            We believe in the power of combining human expertise with AI capabilities. Our solutions 
            are designed to enhance your team's work, not replace it — creating more efficient, 
            consistent, and scalable business operations.
          </p>
        </div>
      </div>

      {/* Custom Solution Request Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black/70 flex items-center justify-center p-4">
          <div className="bg-white rounded-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-start p-6 border-b border-gray-200">
              <div>
                <h2 className="text-xl font-bold font-mono">Request Custom Solution</h2>
                <p className="text-sm text-gray-600">Tell us about your business challenge</p>
              </div>
              <button 
                onClick={() => setShowModal(false)}
                className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
              >
                <X className="w-4 h-4 text-gray-600" />
              </button>
            </div>
            
            <form onSubmit={handleSubmit} className="p-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                <div>
                  <label htmlFor="name" className="block mb-1 text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Your name"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="name@company.com"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="company" className="block mb-1 text-sm font-medium text-gray-700">
                    Company
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Your company name"
                    required
                  />
                </div>
                
                <div>
                  <label htmlFor="businessType" className="block mb-1 text-sm font-medium text-gray-700">
                    Business Type
                  </label>
                  <select
                    id="businessType"
                    name="businessType"
                    value={formData.businessType}
                    onChange={handleChange}
                    className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="real-estate">Real Estate</option>
                    <option value="hospitality">Hospitality</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              
              <div className="mb-4">
                <label htmlFor="challenge" className="block mb-1 text-sm font-medium text-gray-700">
                  Business Challenge
                </label>
                <input
                  type="text"
                  id="challenge"
                  name="challenge"
                  value={formData.challenge}
                  onChange={handleChange}
                  className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="What business problem are you trying to solve?"
                  required
                />
              </div>
              
              <div className="mb-4">
                <label htmlFor="requirements" className="block mb-1 text-sm font-medium text-gray-700">
                  Project Requirements
                </label>
                <textarea
                  id="requirements"
                  name="requirements"
                  value={formData.requirements}
                  onChange={handleChange}
                  rows="4"
                  className="w-full p-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Please describe your requirements and any specific details about your business processes..."
                  required
                ></textarea>
              </div>
              
              <div className="flex items-start mb-6">
                <div className="flex items-center h-5">
                  <input
                    id="privacy"
                    type="checkbox"
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                    required
                  />
                </div>
                <label htmlFor="privacy" className="ml-2 text-xs text-gray-600">
                  I agree to the <a href="#" className="text-blue-600 hover:underline">privacy policy</a> and understand that my information will be used to process my request.
                </label>
              </div>
              
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
                >
                  Submit Request
                </button>
              </div>
              
              <div className="mt-6 flex items-center gap-2 text-xs text-gray-500">
                <Lock className="w-3 h-3" />
                <span>Your information is secure and will only be used to contact you about your request.</span>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomServicesSection;