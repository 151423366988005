import React, { useState } from "react";
import {
  Bot,
  Workflow,
  Building,
  Hotel,
  Check,
  ArrowRight,
  MessageSquare,
  Home,
  Heart,
  Search,
  BookOpen,
  Settings,
  Users,
  Loader,
  LineChart,
} from "lucide-react";

const BusinessInsightsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    businessName: "",
    industry: "",
    email: "",
    role: "",
    challenges: [],
    automationNeeds: [],
    interestedAgents: [],
    suggestedAgent: "",
    budgetRange: "",
    timeline: "",
    additionalInfo: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const industries = [
    { value: "real-estate", label: "Real Estate" },
    { value: "hospitality", label: "Hospitality & Hotels" },
  ];

  const businessChallenges = [
    {
      value: "response-time",
      label: "Slow response times to inquiries",
      icon: MessageSquare,
    },
    {
      value: "lead-management",
      label: "Lead generation and conversion",
      icon: Users,
    },
    {
      value: "scheduling",
      label: "Booking and scheduling management",
      icon: BookOpen,
    },
    {
      value: "customer-service",
      label: "24/7 customer service",
      icon: MessageSquare,
    },
    {
      value: "personalization",
      label: "Personalized client/guest experience",
      icon: Heart,
    },
    {
      value: "operations",
      label: "Operational efficiency",
      icon: Settings,
    },
    {
      value: "market-analysis",
      label: "Market insights and analysis",
      icon: LineChart,
    },
  ];

  const automationNeeds = [
    {
      value: "client-communication",
      label: "Client/Guest Communication",
      description: "Automated responses and follow-ups",
    },
    {
      value: "booking-management",
      label: "Booking/Viewing Management",
      description: "Schedule coordination and reminders",
    },
    {
      value: "property-management",
      label: "Property/Room Management",
      description: "Inventory and maintenance tracking",
    },
    {
      value: "revenue-optimization",
      label: "Revenue Optimization",
      description: "Dynamic pricing and analytics",
    },
    {
      value: "document-processing",
      label: "Document Processing",
      description: "Automated document handling and verification",
    },
    {
      value: "lead-generation",
      label: "Lead Generation",
      description: "Automated lead capture and nurturing",
    },
  ];

  const aiAgents = [
    {
      id: "property-match",
      name: "PropertyMatch AI",
      description: "24/7 real estate agent that handles inquiries and bookings",
      icon: Home,
    },
    {
      id: "concierge-pro",
      name: "ConciergePro AI",
      description: "Personal hotel concierge for guest services",
      icon: Hotel,
    },
    {
      id: "deal-finder",
      name: "DealFinder AI",
      description: "Proactive deal hunter and lead generator",
      icon: Search,
    },
    {
      id: "loyalty-plus",
      name: "LoyaltyPlus AI",
      description: "Relationship builder for repeat business",
      icon: Heart,
    },
  ];

  const budgetRanges = [
    { value: "small", label: "R5,000 - R20,000 per month" },
    { value: "medium", label: "R20,000 - R50,000 per month" },
    { value: "large", label: "R50,000 - R100,000 per month" },
    { value: "enterprise", label: "R100,000+ per month" },
    { value: "undecided", label: "Still determining budget" },
  ];

  const timelineOptions = [
    { value: "immediate", label: "As soon as possible" },
    { value: "quarter", label: "Within this quarter" },
    { value: "sixmonths", label: "Next 3-6 months" },
    { value: "exploring", label: "Just exploring options" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e, category) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        [category]: [...formData[category], value],
      });
    } else {
      setFormData({
        ...formData,
        [category]: formData[category].filter((item) => item !== value),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Post data to webhook URL
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await fetch(
        "https://n8n-aaagentive.onrender.com/webhook/d8d07087-ca62-4463-9399-f4c3769536ac",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log("Form submitted successfully:", formData);
      setSubmitted(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Optional: Add error state handling here
      alert("There was an error submitting your form. Please try again.");
      setIsLoading(false);
    }
  };

  if (submitted) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-12 px-4 sm:px-6 lg:px-8 flex flex-col items-center">
        <div className="w-full max-w-4xl">
          <div className="text-center mb-8">
            <div className="inline-flex items-center">
              <div className="w-10 h-10 relative mr-2">
                <div className="absolute inset-0 bg-blue-100 rounded-full animate-ping opacity-25"></div>
                <img
                  src="/logo-NoB.png"
                  alt="AAAGentive"
                  className="w-10 h-10 relative z-10"
                />
              </div>
              <h1 className="text-2xl font-mono font-bold tracking-tight text-gray-900">
                AAAGentive<span className="text-blue-600">_</span>
              </h1>
            </div>
          </div>

          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-8 mb-8 text-center">
            <div className="inline-flex items-center justify-center w-20 h-20 bg-green-50 rounded-full mb-6">
              <Check className="w-10 h-10 text-green-500" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              Thank You for Your Insights
            </h2>
            <p className="text-gray-600 mb-6 max-w-xl mx-auto">
              We've received your feedback and will be analyzing how our AI
              agents can best serve your needs. Our team will reach out to
              discuss potential solutions that match your requirements.
            </p>
            <div className="inline-flex items-center text-sm font-mono text-blue-600 animate-pulse">
              <Bot className="w-4 h-4 mr-2" />
              <span>AI analysis in progress...</span>
            </div>
          </div>

          <div className="text-center">
            <button
              onClick={() => (window.location.href = "/")}
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 transition-colors"
            >
              <ArrowRight className="w-4 h-4 mr-2 rotate-180" />
              Return to homepage
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="max-w-6xl mx-auto mb-12">
        <div className="text-center mb-12">
          <div className="inline-flex items-center">
            <img
              src="/logo-NoB.png"
              alt="AAAGentive"
              className="w-12 h-12 mr-2"
            />
            <h1 className="text-3xl font-mono font-bold tracking-tight text-gray-900">
              AAAGentive<span className="text-blue-600">_</span>
            </h1>
          </div>
          <h2 className="mt-6 text-2xl sm:text-3xl font-bold text-gray-900 max-w-3xl mx-auto">
            Help Shape the Future of AI in Real Estate & Hospitality
          </h2>
          <p className="mt-3 text-gray-500 max-w-2xl mx-auto">
            Share your insights to help us develop AI agents that truly
            transform your business operations
          </p>
        </div>

        {/* Banner */}
        <div className="bg-gradient-to-r from-blue-500 to-indigo-600 rounded-2xl shadow-lg overflow-hidden mb-16">
          <div className="relative px-6 py-10 sm:px-12 sm:py-16 md:py-20 md:px-16 text-white">
            <div className="relative z-10 max-w-3xl md:pl-10">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">
                Transform Your Business with Intelligent AI Agents
              </h3>
              <p className="text-blue-50 text-sm sm:text-base mb-6 max-w-xl">
                We're developing autonomous AI agents that not only understand
                your business context but can make decisions and take actions to
                drive real results. From property management to guest services,
                our solutions combine human-like interaction with machine
                efficiency.
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-sm">
                <div className="flex items-start">
                  <div className="flex-shrink-0 mr-3 mt-1">
                    <Bot className="w-5 h-5 text-blue-200" />
                  </div>
                  <p>Autonomous agents that handle complex tasks 24/7</p>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 mr-3 mt-1">
                    <Workflow className="w-5 h-5 text-blue-200" />
                  </div>
                  <p>Seamless workflow automation and integration</p>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 mr-3 mt-1">
                    <Building className="w-5 h-5 text-blue-200" />
                  </div>
                  <p>Industry-specific solutions that understand your needs</p>
                </div>
              </div>
            </div>

            {/* Background pattern */}
            <div className="absolute top-0 right-0 w-1/2 h-full opacity-20 pointer-events-none hidden md:block">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 400 400"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.2">
                  <path d="M400 0H0V400H400V0Z" fill="url(#paint0_radial)" />
                  <path
                    d="M174 73L328 227M328 73L174 227"
                    stroke="white"
                    strokeWidth="6"
                  />
                  <circle
                    cx="252"
                    cy="150"
                    r="100"
                    stroke="white"
                    strokeWidth="6"
                  />
                  <path
                    d="M159 201L74 286M121 238L36 323"
                    stroke="white"
                    strokeWidth="6"
                  />
                </g>
                <defs>
                  <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(400 0) rotate(90) scale(400)"
                  >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="max-w-3xl mx-auto">
        <form onSubmit={handleSubmit} className="space-y-12">
          {/* Basic Information */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 sm:p-8">
            <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">
                Business Information
              </h3>
              <p className="text-sm text-gray-500">
                Tell us about your organization
              </p>
            </div>

            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="businessName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Business Name*
                  </label>
                  <input
                    type="text"
                    id="businessName"
                    name="businessName"
                    required
                    value={formData.businessName}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors"
                  />
                </div>

                <div>
                  <label
                    htmlFor="industry"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Industry*
                  </label>
                  <select
                    id="industry"
                    name="industry"
                    required
                    value={formData.industry}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors bg-white"
                  >
                    <option value="">Select your industry</option>
                    {industries.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Business Email*
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors"
                  />
                </div>
                <div>
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Your Role*
                  </label>
                  <input
                    type="text"
                    id="role"
                    name="role"
                    required
                    value={formData.role}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Business Challenges */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 sm:p-8">
            <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">
                Business Challenges
              </h3>
              <p className="text-sm text-gray-500">
                Select the challenges your business is currently facing
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {businessChallenges.map((challenge) => (
                <label
                  key={challenge.value}
                  className="flex items-start p-4 border border-gray-200 rounded-lg hover:border-blue-500 transition-colors cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value={challenge.value}
                    checked={formData.challenges.includes(challenge.value)}
                    onChange={(e) => handleCheckboxChange(e, "challenges")}
                    className="mt-1 mr-3"
                  />
                  <div className="flex-1">
                    <div className="flex items-center mb-2">
                      <challenge.icon className="w-5 h-5 text-blue-600 mr-2" />
                      <span className="text-sm font-medium text-gray-900">
                        {challenge.label}
                      </span>
                    </div>
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Automation Needs */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 sm:p-8">
            <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">
                Automation Needs
              </h3>
              <p className="text-sm text-gray-500">
                What areas of your business could benefit from automation?
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {automationNeeds.map((need) => (
                <label
                  key={need.value}
                  className="flex items-start p-4 border border-gray-200 rounded-lg hover:border-blue-500 transition-colors cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value={need.value}
                    checked={formData.automationNeeds.includes(need.value)}
                    onChange={(e) => handleCheckboxChange(e, "automationNeeds")}
                    className="mt-1 mr-3"
                  />
                  <div className="flex-1">
                    <span className="text-sm font-medium text-gray-900">
                      {need.label}
                    </span>
                    <p className="text-xs text-gray-500 mt-1">
                      {need.description}
                    </p>
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* AI Agents of Interest */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 sm:p-8">
            <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">
                AI Agents of Interest
              </h3>
              <p className="text-sm text-gray-500">
                Which AI agents are you most interested in?
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {aiAgents.map((agent) => (
                <label
                  key={agent.id}
                  className="flex items-start p-4 border border-gray-200 rounded-lg hover:border-blue-500 transition-colors cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value={agent.id}
                    checked={formData.interestedAgents.includes(agent.id)}
                    onChange={(e) =>
                      handleCheckboxChange(e, "interestedAgents")
                    }
                    className="mt-1 mr-3"
                  />
                  <div className="flex-1">
                    <div className="flex items-center mb-2">
                      <agent.icon className="w-5 h-5 text-blue-600 mr-2" />
                      <span className="text-sm font-medium text-gray-900">
                        {agent.name}
                      </span>
                    </div>
                    <p className="text-xs text-gray-500">{agent.description}</p>
                  </div>
                </label>
              ))}
            </div>
          </div>

          {/* Budget and Timeline */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 sm:p-8">
            <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">
                Budget & Timeline
              </h3>
              <p className="text-sm text-gray-500">
                Help us understand your investment capacity and urgency
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="budgetRange"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Budget Range*
                </label>
                <select
                  id="budgetRange"
                  name="budgetRange"
                  required
                  value={formData.budgetRange}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors bg-white"
                >
                  <option value="">Select your budget range</option>
                  {budgetRanges.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  htmlFor="timeline"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Timeline*
                </label>
                <select
                  id="timeline"
                  name="timeline"
                  required
                  value={formData.timeline}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors bg-white"
                >
                  <option value="">Select your timeline</option>
                  {timelineOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Additional Information */}
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6 sm:p-8">
            <div className="mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">
                Additional Information
              </h3>
              <p className="text-sm text-gray-500">
                Anything else we should know?
              </p>
            </div>

            <textarea
              id="additionalInfo"
              name="additionalInfo"
              rows="4"
              value={formData.additionalInfo}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-colors"
              placeholder="e.g., specific pain points, existing tools, or special requirements"
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="bg-black hover:bg-gray-800 text-white px-8 py-3 rounded-lg font-mono transition-colors flex items-center justify-center gap-2"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader className="w-4 h-4 animate-spin" />
                  <span>Processing...</span>
                </>
              ) : (
                <>
                  <span>Submit Insights</span>
                  <ArrowRight className="w-4 h-4" />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessInsightsForm;
